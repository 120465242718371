import React, { useEffect, useState } from "react";
import "./style.scss";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Tabination from "../../reusableComponents/Tabination";
import TraderInfo from "../../components/TradersInfo/TraderInfo";
import Accounts from "../../components/TradersInfo/Accounts";
import Affiliate from "../../components/TradersInfo/Affiliate";
import Discussion from "../../components/TradersInfo/Discussion";
import Iplog from "../../components/TradersInfo/Iplog";
import IPlogs from "../IPlogs";
import AffilateCodes from "../../components/Affiliate/AffiliateCodes";
import TransactionHistory from "../../layouts/TradeList/TransactionHistory";
import AffiliateTable from "../../layouts/TradeList/AffiliateList";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedUser } from "../../store/reducers/userSlice";

function TradersInfo() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const items = [
    {
      name: "Traders Info",
      path: "details",
    },
    {
      name: "Transaction History",
      path: "transaction-history",
    },
    // {
    //   name: "Accounts",
    //   path: "accounts",
    // },
    {
      name: "Affiliates",
      path: "affiliate",
    },
    // {
    //   name: "Discussion",
    //   path: "discussion",
    // },
    {
      name: "IP Log",
      path: "iplog",
    },
  ];

  useEffect(() => {
    const currentPath = location.pathname;

    switch (currentPath) {
      case "/traders-info/account-overview":
        setActiveTab(0);
        break;
      case "/traders-info/transaction-history":
        setActiveTab(1);
        break;
      case "/traders-info/affiliate":
        setActiveTab(2);
        break;
      case "/traders-info/iplog":
        setActiveTab(3);
        break;
    }
  }, [location.pathname]);

  const dispatch = useDispatch();

  const { selectedUser } = useSelector((state) => state.user);

  const [data, setData] = useState(location?.state?.data || selectedUser);

  console.log("data", data);

  const activeUser = useSelector(
    (state) => state.account.accountList.activeUser
  );

  useEffect(() => {
    if (location?.state?.data) {
      dispatch(setSelectedUser(data));
    } else {
      dispatch(setSelectedUser(selectedUser));
    }
  }, [location?.state?.data]);
  return (
    <>
      <Tabination
        variant={3}
        tabItems={items}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="tradersinfo">
        <Routes>
          <Route path="/" element={<Navigate to="details" />} replace={true} />
          <Route path="details" element={<TraderInfo data={data} />} />
          <Route
            path="transaction-history"
            element={<TransactionHistory userDetails={data} />}
          />

          <Route path="accounts" element={<Accounts data={data} />} />
          <Route
            path="affiliate"
            element={<AffiliateTable userData={data} />}
          />
          <Route path="discussion" element={<Discussion data={data} />} />
          <Route path="iplog" element={<IPlogs data={data} />} />
        </Routes>
      </div>
    </>
  );
}

export default TradersInfo;
