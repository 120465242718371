import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deAuthenticate } from "../../store/reducers/authSlice";
import { clearPersistedData } from "../../store/configureStore";
import "./style.scss";

// Import Icons
import Risk from "../../assets/icons/riskmangement.svg";
import RiskActive from "../../assets/icons/riskmangementActive.svg";
import TradersList from "../../assets/icons/traderslist.svg";
import TradersListActive from "../../assets/icons/traderslist-active.svg";
import UserList from "../../assets/icons/userlist.svg";
import UserListActive from "../../assets/icons/userlist-active.svg";
import Payouts from "../../assets/icons/payouts.svg";
import PayoutsActive from "../../assets/icons/payouts-active.svg";
import Payment from "../../assets/icons/payment.svg";
import PaymentActive from "../../assets/icons/paymentActive.svg";
import Logout from "../../assets/icons/logout.svg";
import Support from "../../assets/icons/support.svg";
import StageManager from "../../assets/icons/StageManager.svg";
import StageManagerActive from "../../assets/icons/Stage_Manager_Active.svg";
import SupportActive from "../../assets/icons/supportActive.svg";
import CrossIcon from "../../assets/icons/cross-gray.svg";
import Certificates from "../../assets/icons/Certificates.svg";
import Certificates_Active from "../../assets/icons/Certificates_Active.svg";
import Coupons from "../../assets/icons/coupons.svg";
import CouponsActive from "../../assets/icons/coupons-active.svg";
import AccountCreate from "../../assets/icons/AccountCreate.svg";
import AccountCreateActive from "../../assets/icons/AccountCreateActive.svg";
import Affiliate from "../../assets/icons/affiliate.svg";
import AffiliateActive from "../../assets/icons/affiliate-active.svg";
import Profile from "../../assets/icons/profile.svg";
import Notification from "../../assets/icons/notification.svg";
import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-decrease.svg";
import ip_log from "../../assets/icons/iplog.svg";
import ip_logActive from "../../assets/icons/iplogActive.svg";

// Sidebar Component
function Sidebar({
  headerName,
  zIndexValue,
  setZIndexValue,
  mobileMenu,
  setMobileMenu,
}) {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { custom_permissions } = useSelector((state) => state.auth);

  const sidebarList = [
    {
      title: "Risk Management",
      path: "/risk-management",
      icon: Risk,
      activeIcon: RiskActive,
      adminAccess: custom_permissions?.includes("risk_management"),
    },
    {
      title: "Trader List",
      path: "/super-trader-list/",
      icon: TradersList,
      activeIcon: TradersListActive,
      adminAccess: custom_permissions?.includes("trader_list"),
    },
    {
      title: "Stage Manager",
      path: "/stage-management",
      icon: StageManager,
      activeIcon: StageManagerActive,
      adminAccess: custom_permissions?.includes("stage_manager"),
    },
    {
      title: "User List",
      path: "/user-list",
      icon: UserList,
      activeIcon: UserListActive,
      adminAccess: custom_permissions?.includes("user_management"),
    },
    {
      title: "Create Account",
      path: "/funding-evaluation",
      icon: AccountCreate,
      activeIcon: AccountCreateActive,
      adminAccess: custom_permissions?.includes("account_creation"),
    },
    {
      title: "Certificates",
      path: "/CertificateManagement",
      icon: Certificates,
      activeIcon: Certificates_Active,
      adminAccess: custom_permissions?.includes("certificates"),
      submenu: true,
      items: [
        { title: "All Certificates", path: "/CertificateManagement", tab: 0 },

        {
          title: "Create Certificate",
          path: "/CertificateManagement/create-certificate",
          tab: 1,
        },
      ],
    },
    {
      title: "Payments",
      path: "/paymentlist",
      icon: Payouts,
      activeIcon: PayoutsActive,
      adminAccess: custom_permissions?.includes("payments"),
    },
    {
      title: "Payout",
      path: "/payout_list",
      icon: Payment,
      activeIcon: PaymentActive,
      adminAccess: custom_permissions?.includes("payout"),
    },
    {
      adminAccess: custom_permissions?.includes("coupons"),
      title: "Coupons",
      path: "/coupons",
      icon: Coupons,
      activeIcon: CouponsActive,
      submenu: true,
      items: [
        { title: "All Coupons", path: "/coupons" },
        {
          title: "Create Coupons",
          path: "/coupons/create-coupon",
        },
      ],
    },
    {
      title: "Affiliate Market",
      path: "/affiliate-marketing",
      icon: Affiliate,
      activeIcon: AffiliateActive,
      adminAccess: custom_permissions?.includes("affiliate_market"),
      submenu: true,
      items: [
        {
          title: "Affiliates list",
          path: "/affiliate-marketing/affiliate-list",
        },
        { title: "Codes List", path: "/affiliate-marketing/codes-list" },
        {
          title: "Create Affiliate Code",
          path: "/affiliate-marketing/create-codes",
        },
      ],
    },
    {
      title: "Admin Support",
      path: "/superadmin",
      icon: Support,
      activeIcon: SupportActive,
      adminAccess: custom_permissions?.includes("admin_support"),
    },
    {
      title: "IP Log",
      path: "/ip-log",
      icon: ip_log,
      activeIcon: ip_logActive,
      adminAccess: custom_permissions?.includes("ip_log"),
    },
  ];

  const handleLogout = () => {
    clearPersistedData();
    dispatch(deAuthenticate());
    window.location.reload();
  };

  const closeSideMenu = () => {
    setSideMenuOpen(false);
    setZIndexValue(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSideMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="sidebar" ref={sidebarRef}>
        <ul className="icons_list">
          {sidebarList.map((item, index) => {
            const isActive = location.pathname.startsWith(item.path);
            if (item.adminAccess) {
              return (
                <li className={`icon ${isActive ? "active" : ""}`} key={index}>
                  <Link to={item.path} onClick={closeSideMenu}>
                    <img
                      src={isActive ? item.activeIcon : item.icon}
                      alt={item.title}
                    />
                    {item.title}
                  </Link>
                  {item.submenu && (
                    <SubMenu items={item.items} show={isActive} />
                  )}
                </li>
              );
            }
            return null;
          })}
          <li className="icon">
            <Link to="/signin" onClick={handleLogout}>
              <img src={Logout} alt="logout" />
              Logout
            </Link>
          </li>
        </ul>
      </div>
      {mobileMenu && (
        <MobileMenu
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
          sidebarList={sidebarList}
          handleLogout={handleLogout}
        />
      )}
    </>
  );
}

const SubMenu = ({ items, show }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ul className={`submenu ${show ? "show" : ""}`}>
      {items.map((item) => (
        <li
          key={item.title}
          className={location.pathname === item.path ? "active" : ""}
        >
          <Link to={item.path} onClick={() => navigate(item.path)}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const MobileMenu = ({ mobileMenu, setMobileMenu, sidebarList,handleLogout}) => {
  const location = useLocation(); // Get the current location/pathname

  return (
    <div className={`mobile-menu ${mobileMenu ? "show" : ""}`}>
      <div className="menu_box">
        <div className="close_button" onClick={() => setMobileMenu(false)}>
          <img src={CrossIcon} alt="close icon" />
        </div>
        <ul className="mobile-icons_list">
          {sidebarList.map((item, index) => {
            if (!item.adminAccess) return null; // Skip if no access
            const isActive = location.pathname.startsWith(item.path); // Check if current path matches

            return (
              <li key={index} className={isActive ? "activeMobileMenu" : ""}>
                <Link to={item.path} onClick={() => setMobileMenu(false)}>
                  <img
                    src={isActive ? item.activeIcon : item.icon}
                    alt={item.title}
                  />
                  {item.title}
                </Link>
                {item.submenu && <SubMenu items={item.items} />}
              </li>
            );
          })}
          <li>
            <Link to="/signin" onClick={handleLogout}>
              <img src={Logout} alt="logout" />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
