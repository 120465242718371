import React from "react";
import ReactApexChart from "react-apexcharts";
import "./style.scss";
import moment from "moment";
import { formatCurrency } from "../../../utils/helpers/string";

function StageStatistics({
  title,
  chartType = "line",
  seriesData,
  categories = [],
  colors = ["#4CB944"],
  sidebarType,
  bottomType,
  yAxisType,
}) {
  // Ensure seriesData is valid
  const validSeriesData = Array.isArray(seriesData) ? seriesData : [];
  console.log(validSeriesData, "validSeriesData");
  // Default options for the chart
  const options = {
    chart: {
      type: chartType,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set to true if you want horizontal bars
        columnWidth: seriesData.length > 5 ? "10%" : "50%", // Adjust column width as needed
        endingShape: "rounded", // Optional: sets the shape of the bar ends
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      offsetY: 10,
      offsetX: -10,
      itemMargin: {
        horizontal: 16,
      },
    },
    stroke: {
      curve: "smooth", // Make the line smooth
      width: 2,
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      title: {
        text: bottomType || "Days",
      },
      labels: {
        formatter: function (value) {
          // Assuming 'value' is a date string or timestamp
          const date = new Date(value);
          return bottomType === "Month"
            ? moment(date).format("MMM.YY")
            : moment(date).format("DD.MM.YY");
        },
      },
    },
    yaxis: {
      title: {
        text: sidebarType || "value",
      },
      labels: {
        formatter: function (value) {
          return yAxisType === "USD" ? formatCurrency(value) : value;
        },
      },
    },
    fill: {
      opacity: 0.3,
    },
    markers: {
      size: 5,
      strokeWidth: 2,
    },
  };

  return (
    <div className="stagestats">
      <div className="stagestats_wrapper">
        <h3 className="chart_title">{title}</h3>
        <div className="chart_wrapper">
          <div className="chartBox">
            <Chart
              options={options}
              seriesData={validSeriesData}
              chartType={chartType}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StageStatistics;

function Chart({ options, chartType, seriesData }) {
  return (
    <ReactApexChart
      options={options}
      series={seriesData}
      type={chartType}
      height={280}
    />
  );
}
