import React, { useEffect, useState } from "react";
import "./style.scss";
import ArrowIncrease from "../../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../../assets/icons/arrow-decrease.svg";
import Loss from "../../../assets/images/loss-chart.svg";
import InfoBox from "../../../reusableComponents/InfoBox";
import TrophyIcon from "../../../assets/icons/trophy-white.svg";
import ContestantsIcon from "../../../assets/icons/people-white.svg";
import Gain from "../../../assets/images/gain-chart.svg";
import PushLeads, { Chart } from "./PushLeads";
import Visitor from "./Visitor";
import OverviewTable from "./OverviewTable";
import AreaPreviewChart from "./AreaPreviewChart";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../../store/reducers/authSlice";
import {
  baseUrl,
  getAffiliateCodelist,
  getCodelistV2,
} from "../../../utils/api/apis";
import { Tooltip } from "react-tooltip";
import { returnErrors } from "../../../store/reducers/error";
import { getAffiliateDataSuccess } from "../../../store/reducers/affiliateSlice";
import Select from "react-select";
import { formatCurrency } from "../../../utils/helpers/string";

function Overview({ setActiveTab }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idToken = useSelector((state) => state.auth.idToken);
  const affiliateId = searchParams.get("affiliateId");
  const showStats = searchParams.get("showStats");
  const [activeCode, setActiveCode] = useState(false);

  const dispatch = useDispatch();
  const [affiliateStats, setAffiliateStats] = useState({});
  const fetch = async () => {
    const searchParams = new URLSearchParams(location.search);
    const affiliate_id = searchParams.get("affiliateId");

    if (!affiliate_id) return;

    dispatch(setIsLoading(true));
    let config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(
      `${baseUrl}affiliates/admin/affiliate-stats/?affiliate_code_id=${affiliateId}`,
      config
    );
    // console.log(response.data, "TestData");
    setAffiliateStats(response.data);
    dispatch(setIsLoading(false));
  };

  const [tableData, setTableData] = useState([]);
  const [codeData, setCodeData] = useState([]);
  const [pushLeadData, setPushLeadData] = useState([]);
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);

  console.log("codeData", codeData);

  const fetchTable = async () => {
    dispatch(setIsLoading(true));

    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    console.log("emailE", email);
    const response = await getCodelistV2(idToken, email);
    console.log(response?.data?.results, "TestData");
    setTableData(response?.data?.results);
    response.status > 399
      ? dispatch(returnErrors("Error Fetching Affiliate List", 400))
      : setTableData(response?.data?.results);
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetchTable();
  }, [idToken]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "20px",
      boxSizing: "content-box",
      fontSize: "12px",
      color: "#133C55",
      fontWeight: "500",
      fontFamily: "Space Grotesk",
      borderRadius: "8px",
      float: "right",
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#133C55",
        width: "20px",
      }),
    }),
  };

  // code options for dropdown
  const codeOptions = tableData?.map((item) => ({
    value: item?.id,
    label: `${item?.code}`,
  }));

  // Handle change for react select
  const handleChange = async (selectedOption) => {
    setIsDropdownSelected(true);
    dispatch(setIsLoading(true));
    setActiveCode(selectedOption);
    console.log("selectedOption", selectedOption);
    const codeId = selectedOption?.value;
    let config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(
      `${baseUrl}affiliates/admin/affiliate-code-stats/?code_id=${codeId}`,
      config
    );
    const responseTwo = await axios.get(
      `${baseUrl}affiliates/admin/affiliate-stats/?affiliate_code_id=${codeId}`,
      config
    );

    setCodeData(response?.data);
    setPushLeadData(responseTwo?.data);
    // setActiveCode(true);

    console.log("first", response);
    dispatch(setIsLoading(false));
  };

  console.log("tableData", codeData);

  useEffect(() => {
    if (codeOptions.length > 0) {
      handleChange(codeOptions[0]);
    }
  }, [idToken]);

  // useEffect(() => {
  //   fetch();
  // }, [idToken]);

  // useEffect(() => {
  //   setActiveTab(2);
  //   affiliateId && fetch();
  // }, [affiliateId]);
  const infoBoxArr = [
    {
      title: "Conversion",
      value: tableData?.[0]?.conversions,
      icon: ArrowIncrease,
      // percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Push leads",
      value: pushLeadData?.pushed_leads,
      icon: ArrowIncrease,
      // percentage: "-1.2%",
      color: "#4CB944",
    },
    {
      title: "Commission Amount",
      value: formatCurrency(Number(codeData?.commission_amount)),
      icon: ArrowIncrease,
      // percentage: "+5.8%",
      color: "#1877F2",
    },
    {
      title: "Total failed conversions",
      value: codeData?.total_failed_conversions,
      icon: ArrowIncrease,
      // percentage: "+5.8%",
      color: "#1877F2",
    },
    {
      title: "Total succeeded conversions",
      value: codeData?.total_succeeded_conversions,
      icon: ArrowIncrease,
      // percentage: "+5.8%",
      color: "#1877F2",
    },
    // {
    //   title: "Win Rate",
    //   value: "500",
    //   icon: ArrowDecrease,
    //   percentage: "+10%",
    //   color: "#DF2935",
    // },
  ];

  const competitionsArr = [
    {
      title: "New Users",
      value: "+15",
      img: Gain,
    },
    {
      title: "Page Views",
      value: "-4",
      img: Loss,
    },
  ];

  return (
    <div className="overview">
      <Tooltip id="my-tooltip" />

      {
        <div className="grid_container">
          <div className="papercon multi">
            <div className="innerIcon">
              <div class="HeadT">Email</div>
              <div class="TextB">{tableData?.[0]?.email}</div>
            </div>
            <div className="innerIcon">
              <div class="HeadT">Total codes</div>
              <div class="TextB">{tableData?.length}</div>
            </div>
            <div className="innerIcon">
              <div class="HeadT">
                Active code{" "}
                <a
                  style={{ position: "relative", zIndex: "1000" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Code that corresponds to the data represented in the graph below"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0 0 32 32"
                    style={{ position: "absolute", zIndex: "1000" }}
                  >
                    <path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z"></path>
                  </svg>
                </a>
              </div>
              <div class="TextB">{activeCode ? activeCode.label : "-"}</div>
            </div>
            <div className="code_dropdown">
              <div className="HeadT">Code List</div>
              <Select
                options={codeOptions}
                styles={customStyles}
                onChange={handleChange}
                // value={codeOptions[0]}
              />
            </div>
          </div>

          {isDropdownSelected ? (
            <>
              <div className="column column-70">
                <div className="row-1">
                  <div className="infobox_col1_container">
                    {infoBoxArr?.map((info) => (
                      <InfoBox
                        key={info.title}
                        title={info.title}
                        value={info.value}
                        icon={info.icon}
                        percentage={info.percentage}
                        color={info.color}
                        variant={1}
                      />
                    ))}
                  </div>
                </div>
                <div className="box row-2 push_leads">
                  <PushLeads data={pushLeadData?.click_stats || []} />
                </div>
              </div>
            </>
          ) : (
            <div className="initial_heading">
              Please select Code from Code list
            </div>
          )}

          {/* <div className="column column-30">
          <div className="row-1">
            <div className="infobox_col2_container">
              {competitionsArr.map((info) => (
                <InfoBox
                  key={info.title}
                  title={info.title}
                  value={info.value}
                  img={info.img}
                  variant={3}
                />
              ))}
            </div>
          </div>
          <div className="box visitor row-2">
            <Visitor />
          </div>
        </div> */}
        </div>
      }

      {isDropdownSelected && (
        <div className="box table_container">
          {<OverviewTable codeData={codeData || []} />}
        </div>
      )}
    </div>
  );
}

export default Overview;
