import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { usePagination, useTable } from "react-table";
import ChartGain from "../../../assets/images/chart-rep-gain.png";
import ChartLoss from "../../../assets/images/chart-rep-loss.png";
import StarBlueIcon from "../../../assets/icons/star-blue-icon.svg";
import StarGreyIcon from "../../../assets/icons/star-gray-icon.svg";
import ArrowUpIcon from "../../../assets/icons/arrow-up-white.svg";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as SearchNew } from "../../../assets/icons/search-svgrepo-com (1).svg";

import { useDispatch, useSelector } from "react-redux";
import {
  generateContractRequest,
  getSupportTableDetailsNew,
  updateUserAccountReq,
  upgradeFundedUserAccountReq,
} from "../../../utils/api/apis";
import { returnErrors } from "../../../store/reducers/error";
import { setIsLoading } from "../../../store/reducers/authSlice";
import moment from "moment";
import ConfirmationModal from "../../../pages/FundingEvaluation/ConfirmationModal";
import { returnMessages } from "../../../store/reducers/message";
import Select from "react-select";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import CrossMark from "../../../assets/images/delete_14024972.png";
import RightMark from "../../../assets/images/check_5610944.png";
import {
  setActiveAccount,
  setActiveUser,
} from "../../../store/reducers/accountSlice";
import { Button } from "rsuite";

const statusOptions = [
  { label: "All", value: null },
  { label: "New", value: "new" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];
// component
function StagePass2({ type }) {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTraders, setTotalTraders] = useState(0);
  const [allTraders, setAllTraders] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [status, setStatus] = useState(null);
  console.log(status, "statusstatusstatusstatus");
  const [search, setSearch] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  // server side pagination logic

  const addPadding = (tableData, pageNumber) => {
    const padding = [];
    for (let index = 0; index < (pageNumber - 1) * 50; index++) {
      padding.push({
        id: `padding-${index}`,
        name: "",
        accountNumber: "",
        startDate: "",
        endDate: "",
        percentageOfGoodTrades: "",
        percentageOfBadTrades: "",
      });
    }

    return [...padding, ...tableData];
  };
  const handlePagination = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      // let searchValue = search;
      try {
        setAllTraders([]);
        const data = await getSupportTableDetailsNew(
          idToken,
          pageNumber,
          type,
          "",
          status
        );

        setAllTraders(addPadding(data.results, pageNumber));
        setCurrentPage(pageNumber);
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    }
  };
  const [platform, setPlatform] = useState();

  const fetchData = async () => {
    dispatch(setIsLoading(true));
    const pageNum = search || type ? 1 : currentPage || 1;

    try {
      const data = await getSupportTableDetailsNew(
        idToken,
        pageNum,
        type,
        severity,
        status,
        search,
        platform
      );
      setTotalTraders(data.count);
      setAllTraders(addPadding(data.results, currentPage));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(returnErrors("Something went wrong", 400));
      dispatch(setIsLoading(false));
    }
  };
  console.log(currentPage, "currentPagecurrentPagecurrentPage");

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [severity, type, status, search, refresh, platform]);

  // search bar and filters logic
  const inputRef = useRef(null);

  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  return (
    <div className=" economiccalender-container">
      <div className="economiccalender-subcontainer">
        <div className="economiccalender-innercontainer filter-header">
          <form
            className="search_container"
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(searchValue);
              setCurrentPage(1);
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
            />
            <button type="submit">
              <div style={{ padding: "3px 10px 0 0" }}>
                <SearchNew style={{ width: "100%" }} />
              </div>
            </button>
          </form>
          <div className="sort_buttons">
            <div className="mobile_dropDowns">
              {" "}
              <Select
                placeholder="Status"
                classNamePrefix="react-select"
                options={statusOptions}
                value={status}
                onChange={(selectedOption) =>
                  selectedOption.value === null
                    ? setStatus(null)
                    : setStatus(selectedOption)
                }
              />
            </div>

            <div
              className="tabContainer desktop_pills"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                width: "100%",
              }}
            >
              {statusOptions.map((tab, index) => (
                <div
                  key={index}
                  className={
                    status && status.label === tab.label
                      ? "active"
                      : "normalbtn"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => setStatus(tab)}
                >
                  {tab.label}
                </div>
              ))}
              <div
                className={platform === "matchtrade" ? "active" : "normalbtn"}
                onClick={() => {
                  setCurrentPage(1);
                  setPlatform("matchtrade");
                }}
                style={{ cursor: "pointer" }}
              >
                Matchtrade
              </div>
              <div
                className={platform === "tradelocker" ? "active" : "normalbtn"}
                onClick={() => {
                  setCurrentPage(1);
                  setPlatform("tradelocker");
                }}
                style={{ cursor: "pointer" }}
              >
                Tradelocker
              </div>
            </div>
          </div>
        </div>
        <div className="economiccalender-container_table">
          <Table
            tableData={allTraders || []}
            handlePagination={handlePagination}
            currentPage={currentPage - 1}
            totalTraders={totalTraders}
            fetchData={fetchData}
            setRefresh={setRefresh}
            refresh={refresh}
            type={type}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}
const handleCopyToClipboard = (text) => {
  toast("Copied email", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "success",
    theme: "dark",
    className: "custom-toast-container",
  });
};
export default StagePass2;

const Table = ({
  tableData,
  handlePagination,
  currentPage,
  totalTraders,
  fetchData,
  setRefresh,
  refresh,
  type,
  setCurrentPage,
}) => {
  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const handleFilter = (status) => {
    setFilter(status);
  };
  //ACTION BUTTONS LOGIC

  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
  const [userData, setUserData] = useState({});
  const handleUpgradeConfirm = (row) => {
    setUserData({
      id: row?.original.id,
    });

    setIsConfirmation(true);
  };

  const handleUpdateConfirm = (row, type) => {
    setUserData({
      login_id: row?.original.login_id,
      type: type.type,
    });

    setIsConfirmationDelete(true);
  };

  const idToken = useSelector((state) => state.auth.idToken);
  const handleUpgradeAccount = async () => {
    dispatch(setIsLoading(true));
    const response = await upgradeFundedUserAccountReq(idToken, userData).then(
      (response) =>
        response.status < 399
          ? dispatch(returnMessages("Funded User Created Successfully", 201))
          : dispatch(returnErrors("Failed to Create Funded User", 400))
    );
    fetchData();
  };
  const handleUpdateAccount = async () => {
    dispatch(setIsLoading(true));
    const response = await updateUserAccountReq(idToken, userData).then(
      (response) =>
        response.status < 399
          ? dispatch(returnMessages("Account Updated Successfully", 201))
          : dispatch(returnErrors("Failed to Update Account", 400))
    );
    fetchData();
  };

  console.log(type, "typetypetypetype");
  const typename = type === "1_step" ? "1 Step id" : "2 Step id";
  const handleActiveAccount = (row, msg) => {
    row?.original?.account && msg === "account"
      ? dispatch(setActiveAccount(row?.original.account))
      : row?.original?.account && msg === "funded_account"
      ? dispatch(setActiveAccount(row?.original?.funded_account))
      : dispatch(setActiveAccount(null));
    dispatch(setActiveUser({ ...row?.original }));
  };

  const ActionCell = ({
    row,
    generateContractRequest,
    idToken,
    dispatch,
    returnMessages,
    returnErrors,
    fetchData,
    setRefresh,
    refresh,
  }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [selectedAction, setSelectedAction] = React.useState("");
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleConfirmClose = () => {
      setConfirmOpen(false);
      setSelectedAction("");
    };

    const handleConfirmOpen = (action) => {
      setSelectedAction(action);
      setConfirmOpen(true);
    };

    const handleGenerateContract = async () => {
      const data = {
        action: selectedAction
          ? selectedAction
          : row?.original?.contract_issued
          ? "revoke"
          : "generate",
      };
      const response = await generateContractRequest(
        idToken,
        row?.original?.id,
        data
      );
      if (response?.data?.detail) {
        dispatch(returnMessages(response?.data?.detail, response?.status));
        fetchData();
        setRefresh(!refresh);
      } else {
        dispatch(
          returnErrors(
            response?.response?.data?.detail,
            response?.response?.status
          )
        );
      }
      handleConfirmClose();
    };

    return (
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              minWidth: "150px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {row?.original.status === "approved" ? (
            <MenuItem>No Actions</MenuItem>
          ) : (
            <>
              {row?.original.status === "new" && (
                <>
                  {" "}
                  <MenuItem
                    onClick={() =>
                      handleConfirmOpen(
                        row?.original.contract_issued ? "revoke" : "generate"
                      )
                    }
                  >
                    {row?.original.contract_issued ? "Revoke" : "Generate"}{" "}
                    contract
                  </MenuItem>
                  <MenuItem onClick={() => handleConfirmOpen("reject")}>
                    Reject Contract
                  </MenuItem>
                </>
              )}
              {(row?.original.status === "rejected" ||
                row?.original.status === "revoked" ||
                row?.original.status === "in_progress") && (
                <>
                  <MenuItem onClick={() => handleConfirmOpen("hard_reset")}>
                    Hard Reset
                  </MenuItem>
                </>
              )}
            </>
          )}
        </Menu>

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmOpen}
          onClose={handleConfirmClose}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle id="confirm-dialog-title">Confirm Action</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              Are you sure you want to {selectedAction?.replace("_", " ")} this
              contract?
            </DialogContentText>
          </DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Button
              style={{
                width: "100%",
                backgroundColor: "#fd504f",
                color: "white",
              }}
              onClick={handleConfirmClose}
              color="error"
            >
              Cancel
            </Button>
            <Button
              style={{ width: "100%" }}
              onClick={handleGenerateContract}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </div>
        </Dialog>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => {
          const [randomColor, setRandomColor] = useState();
          const getRandomColor = `#${Math.floor(
            Math.random() * 16777215
          ).toString(16)}`;
          if (!randomColor) {
            setRandomColor(getRandomColor);
          }

          return (
            <>
              <div className="column_one_wrapper">
                <div>{value}</div>
                <CopyToClipboard onCopy={handleCopyToClipboard} text={value}>
                  {/* <button> */}
                  <p
                    className="table_copy_button"
                    style={{
                      marginRight: "20px",
                      // backgroundColor: "#35d269",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#FFF" />
                      <path
                        d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                        stroke="#36D66B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </CopyToClipboard>
              </div>
            </>
          );
        },
      },
      {
        Header: "Full Name",
        accessor: "full_name",
        Cell: ({ value }) => <> {value ? value : "-"}</>,
      },
      {
        Header: typename,
        accessor: "account",
        Cell: ({ row }) => (
          <Link
            to="/traders-list-2"
            onClick={() => handleActiveAccount(row, "account")}
          >
            {row?.original.account ? row?.original.account : "-"}
          </Link>
        ),
      },

      {
        Header: "Funded",
        accessor: "funded_account",
        Cell: ({ row }) => (
          <Link
            to="/traders-list-2"
            onClick={() => handleActiveAccount(row, "funded_account")}
          >
            {row?.original.funded_account ? row?.original.funded_account : "-"}
          </Link>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            style={{
              textWrap: "nowrap",
              textTransform: "capitalize",
              color:
                value === "approved"
                  ? "#209829"
                  : value === "rejected"
                  ? "#FA4D4D"
                  : value === "new"
                  ? "#00000"
                  : "#FFB100",
            }}
          >
            {value ? value.replace(/_/g, " ") : "-"}
          </span>
        ),
      },
      // {
      //   Header: "Subject",
      //   accessor: "subject",
      //   Cell: ({ row }) => row?.original.description,
      // },
      // {
      //   Header: "contract",
      //   accessor: "is_contract_verified",
      //   Cell: ({ row }) => (
      //     <img
      //       width={"25px"}
      //       src={row?.original.is_contract_verified ? RightMark : CrossMark}
      //       alt=""
      //     />
      //   ),
      // },
      // {
      //   Header: "Kyc Verified",
      //   accessor: "is_kyc_verified",
      //   Cell: ({ row }) => (
      //     <img
      //       width={"25px"}
      //       src={row?.original.is_kyc_verified ? RightMark : CrossMark}
      //       alt=""
      //     />
      //   ),
      // },
      {
        Header: "Contract Issued",
        accessor: "contract_issued",
        Cell: ({ row }) => (
          <img
            width={"25px"}
            src={
              row?.original.contract_issued ||
              row?.original.status === "approved"
                ? RightMark
                : CrossMark
            }
            alt=""
          />
        ),
      },
      {
        Header: "KYC verified",
        accessor: "kyc_verified",
        Cell: ({ row }) => (
          <img
            width={"25px"}
            src={row?.original.kyc_verified ? RightMark : CrossMark}
            alt=""
          />
        ),
      },
      {
        Header: "Date (created at)",
        accessor: "created_at",
        Cell: ({ row }) =>
          moment(row?.original.created_at).format("MMMM Do YYYY, h:mm:ss a"),
      },
      {
        Header: "Date (last updated)",
        accessor: "updated_at",
        Cell: ({ row }) =>
          moment(row?.original.updated).format("MMMM Do YYYY, h:mm:ss a"),
      },

      // { Header: "Username", accessor: "username" },
      // {
      //   Header: "Email Generated",
      //   accessor: "emailgen",
      //   Cell: ({ value }) => (
      //     <div className={`${value} gen-tag`}>
      //       <span>
      //         <img
      //           src={
      //             value === "Created"
      //               ? GreenTickIcon
      //               : value === "In progress"
      //               ? InProgressIcon
      //               : ErroIcon
      //           }
      //           alt={value}
      //         />
      //       </span>
      //       {value}
      //     </div>
      //   ),
      // },
      // {
      //   Header: "Credentials Gen.",
      //   accessor: "credentialgen",
      //   Cell: ({ value }) => (
      //     <div className={`${value} gen-tag`}>
      //       <span>
      //         <img
      //           src={
      //             value === "Created"
      //               ? GreenTickIcon
      //               : value === "In progress"
      //               ? InProgressIcon
      //               : ErroIcon
      //           }
      //           alt={value}
      //         />
      //       </span>
      //       {value}
      //     </div>
      //   ),
      // },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <ActionCell
            row={row}
            generateContractRequest={generateContractRequest}
            idToken={idToken}
            dispatch={dispatch}
            returnMessages={returnMessages}
            returnErrors={returnErrors}
            fetchData={fetchData}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        ),
      },
    ],
    [typename, tableData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData || [],
      initialState: { pageIndex: currentPage, pageSize: 50 },
    },
    usePagination
  );
  const totalPages = Math.ceil(totalTraders / pageSize);

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleChange = (event, value) => {
    console.log(typename, "ahjgahusdf");
    handlePagination(value);
  };

  // const [gotoPageInput, setGotoPageInput] = useState();

  // const handleGotoPage = () => {
  //   const pageNumber = parseInt(gotoPageInput, 10);
  //   if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
  //     gotoPage(pageNumber - 1);
  //     setCurrentPage(pageNumber); // Adjusting page number to 0-based index
  //     setGotoPageInput(""); // Clearing the input field after navigation
  //   }
  // };
  return (
    <>
      <div>
        {isConfirmation && (
          <ConfirmationModal
            setConfirmationModal={setIsConfirmation}
            onConfirm={handleUpgradeAccount}
          />
        )}
        {isConfirmationDelete && (
          <ConfirmationModal
            setConfirmationModal={setIsConfirmationDelete}
            onConfirm={handleUpdateAccount}
          />
        )}
        {/* <div className="table_header">
          <div className="search">
            <div className="search_bar">
              <input
                type="text"
                className="search_input"
                placeholder="Search..."
              />
              <img className="search_icon" src={Search} alt="search icon" />
            </div>
            <div className="select_wrapper">
              <div
                className="calendar-button"
                onClick={() => setIsCalender((prev) => !prev)}
              >
                <img src={CalendarIcon} alt="calender icon" />
              </div>
              <div className="calendar_wrapper">
                {isCalender && (
                  <Calendar
                    onChange={(date) => setDate(date)}
                    value={date}
                    // formatMonth={"MMM"}
                    selectRange={true}
                    showNeighboringMonth={false}
                  />
                )}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="filter_buttons">
            <button
              className={filter === "All" ? "active_btn" : ""}
              onClick={() => handleFilter("All")}
            >
              All
            </button>
            <button
              className={filter === "Accepted" ? "active_btn" : ""}
              onClick={() => handleFilter("Accepted")}
            >
              Accepted
            </button>
            <button
              className={filter === "Rejected" ? "active_btn" : ""}
              onClick={() => handleFilter("Rejected")}
            >
              Rejected
            </button>
            <button
              className={filter === "Flagged" ? "active_btn" : ""}
              onClick={() => handleFilter("Flagged")}
            >
              Flagged
            </button>
          </div>
        </div> */}

        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row?.getRowProps()}
                    className={`economiccalender-row ${
                      selectedRow === rowIndex
                        ? "economiccalender-row-active"
                        : ""
                    } `}
                    // onClick={() => setSelectedRow(rowIndex)}
                  >
                    {row?.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.column.id === "importance" ? (
                          <Importance activeStars={cell.value} />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                  {selectedRow === rowIndex && (
                    <tr>
                      <td colSpan="7">
                        <div className="supportcases-td-container">
                          <div className="td-main-div">
                            <h3>Stage - I Evaluation Summary</h3>
                            <div className="supportcases-type-details">
                              <div className="supportcases-box">
                                <p>Martingale success</p>
                                <h4>${row?.original.successRate}</h4>
                                <div className="success-ratio">
                                  <span>
                                    <img src={ArrowUpIcon} alt="ArrowUpIcon" />
                                  </span>
                                  +2.53%
                                </div>
                              </div>
                              <div className="supportcases-box">
                                <p>Max Loss</p>
                                <h4 className="red">
                                  ${row?.original.maxLoss}
                                </h4>
                                <img src={ChartLoss} alt="ChartLoss" />
                              </div>
                              <div className="supportcases-box">
                                <p>Max Daily Loss</p>
                                <h4 className="red">
                                  ${row?.original.maxDailyLoss}
                                </h4>
                                <img src={ChartLoss} alt="ChartLoss" />
                              </div>
                              <div className="supportcases-box">
                                <p>Min Trading Days</p>
                                <h4 className="green">
                                  {row?.original.minTradeDays}
                                </h4>
                                <img src={ChartGain} alt="ChartGain" />
                              </div>
                              <div className="supportcases-box dates">
                                <div className="report-dates">
                                  <div className="upper-date">
                                    <p>Purchase Date</p>
                                    <h4>{row?.original.purchaseDt}</h4>
                                  </div>
                                  <div className="lower-date">
                                    <p>Account Started</p>
                                    <h4>{row?.original.reportDt}</h4>
                                  </div>
                                </div>
                                <div className="report-dates">
                                  <div className="upper-date">
                                    <p>ADTI Report</p>
                                    <h4>{row?.original.accStartDt}</h4>
                                  </div>
                                  <div className="lower-date">
                                    <p>Account Pass</p>
                                    <h4>{row?.original.accPassDt}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="supportcases-comments">
                              <p>Comments</p>
                              <h4>{row?.original.desc}</h4>
                              <Link to="">
                                Click here for full event details.
                              </Link>
                            </div>
                            <div className="supportcases-category">
                              <div className="category-details">
                                <p>Importance:</p>
                                <h5>
                                  <Importance
                                    activeStars={row?.original.importance}
                                  />
                                </h5>
                              </div>
                              <div className="category-details">
                                <p>Created</p>
                                <h5>{row?.original.created}</h5>
                              </div>
                              <div className="category-details">
                                <p>Category</p>
                                <h5>{row?.original.category}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>

        <div style={{ padding: "0px 16px 16px 16px" }}>
          <div className="spacerT30p"></div>
          <Pagination
            onChange={handleChange}
            count={totalPages}
            variant="outlined"
            page={currentPage + 1}
            color="primary"
          />
          {/* <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button className="goToButton" onClick={handleGotoPage}>
              Go
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

function Importance({ activeStars }) {
  let totalStars = 3;
  let inactiveStars = totalStars - activeStars;
  const stars = [];
  for (let i = 0; i < activeStars; i++) {
    stars.push(
      <img
        src={StarBlueIcon}
        alt=""
        key={`active${i}`}
        className="importance-star"
      />
    );
  }
  for (let i = 0; i < inactiveStars; i++) {
    stars.push(
      <img
        src={StarGreyIcon}
        alt=""
        key={`inactive${i}`}
        className="importance-star"
      />
    );
  }
  return <>{stars}</>;
}
