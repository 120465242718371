import React, { useEffect, useState } from "react";
import "./style.scss";

import CheckGreenIcon from "../../../../assets/icons/check-green.svg";
import WarningRedIcon from "../../../../assets/icons/warning-red.svg";
import ClockGoldIcon from "../../../../assets/icons/clock-gold.svg";
import CheckFullGreenIcon from "../../../../assets/icons/check-full-green.svg";
import WarningFullRedIcon from "../../../../assets/icons/warning-full-red.svg";
import ClockFullYellowIcon from "../../../../assets/icons/clock-full-yellow.svg";
import CaretDownIcon from "../../../../assets/icons/caret-down.svg";

import Tabination from "../../../../reusableComponents/Tabination";
import { formatCurrency } from "../../../../utils/helpers/string";

function RevenueGrowth({ programObjective }) {
  const item = [
    { name: "All" },
    { name: "Passed" },
    { name: "In Progress" },
    { name: "Failed" },
  ];
  const ActiveTab = JSON.parse(localStorage.getItem("activeTab")) || {
    name: "All",
  };
  const New =
    ActiveTab.name === "Passed"
      ? { name: "passed" }
      : ActiveTab.name === "In Progress"
      ? { name: "in_progress" }
      : ActiveTab.name === "Failed"
      ? { name: "failed" }
      : ActiveTab;
  const activeTabIndex = item.findIndex((item) => item.name === ActiveTab.name);
  const [activeTab, setActiveTab] = useState(activeTabIndex);
  useEffect(() => {}, [activeTab]);
  useEffect(() => {
    switch (ActiveTab.name) {
      case "All":
        setActiveTab(0);
        break;

      case "passed":
        setActiveTab(1);
        break;
      case "In Progress":
        setActiveTab(2);
        break;
      case "Failed":
        setActiveTab(3);
        break;
      default:
        break;
    }
  }, [ActiveTab.name]);
  return (
    <div className="revenuegrowth-container">
      <div className="revenuegrowth-subcontainer">
        <div className="revenuegrowth-header">
          <h4>Program Objectives</h4>
          <Tabination
            variant={3}
            tabItems={item}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            size="12px"
            tab={true}
            tabStyle={{
              margin: "0 2em 0 0",
              fontSize: "12px",
              textWrap: "nowrap",
            }}
          />
        </div>
        {/* <div className="revenuegrowth-innercontainer revenuegrowth-trade">
          <img src={CheckFullGreenIcon} alt="Check Icon" />
          <div>
            <h4>Minimum 10 Trading Days</h4>
            <p>
              This value represents the number of your active trading days as
              measured in the CE(S)T timezone.
            </p>
          </div>
          <div className="revenuegrowth-container_status">
            <p>Result: 10</p>
            <p className="status status-passed">passed</p>
          </div>
        </div> */}
        <div
          className="revenuegrowth-innercontainer"
          style={{
            display:
              New?.name === programObjective?.max_daily_loss_status ||
              New?.name === "All"
                ? "flex"
                : "none",
          }}
        >
          <div style={{ flex: "1" }}>
            {programObjective?.max_daily_loss_status === "in_progress" ? (
              <img src={ClockGoldIcon} alt="Clock Icon" />
            ) : programObjective?.max_daily_loss_status === "failed" ? (
              <img src={WarningRedIcon} alt="Check Icon" />
            ) : (
              <img
                style={{ minWidth: "10px" }}
                src={CheckGreenIcon}
                alt="Check Icon"
              />
            )}

            <div style={{ display: "flex" }}>
              <h4>
                {" "}
                Max Daily Loss Target: $
                {programObjective?.max_daily_loss_target}
              </h4>
              <p>
                Remaining:{" "}
                {formatCurrency(programObjective?.remaining_daily_max_loss)}
              </p>
              <p>
                Threshold:{" "}
                {formatCurrency(programObjective?.max_daily_loss_threshold)}
              </p>
              {/* <h4 className={programObjective?.max_daily_loss < 0 ? "loss" : programObjective?.max_daily_loss > 0 ? "profit" : null}>Result: ${programObjective?.remaining_daily_max_loss}</h4> */}
            </div>
          </div>
          <p
            style={{ textTransform: "capitalize" }}
            className={
              programObjective?.max_daily_loss_status === "in_progress"
                ? "status Pending_tag"
                : programObjective?.max_daily_loss_status === "failed"
                ? "status Inactive_tag"
                : programObjective?.max_daily_loss_status === "passed"
                ? "status Active_tag"
                : "status"
            }
          >
            {programObjective?.max_daily_loss_status?.replace("_", " ")}
          </p>
        </div>

        <div
          className="revenuegrowth-innercontainer"
          style={{
            display:
              New?.name === programObjective?.profit_status ||
              New?.name === "All"
                ? "flex"
                : "none",
          }}
        >
          <div style={{ flex: "1" }}>
            {programObjective?.profit_status === "in_progress" ? (
              <img src={ClockGoldIcon} alt="Clock Icon" />
            ) : programObjective?.profit_status === "failed" ? (
              <img
                style={{ minWidth: "10px" }}
                src={WarningRedIcon}
                alt="Check Icon"
              />
            ) : (
              <img src={CheckGreenIcon} alt="Check Icon" />
            )}
            {/* <img src={WarningFullRedIcon} alt="Warning Icon" /> */}
            {/* <div>
              <h4>{`Profit - ${programObjective?.profit}$`}</h4>
            </div> */}
            <div style={{ display: "flex" }}>
              <h4>
                Profit Target: {formatCurrency(programObjective?.profit_target)}
              </h4>
              <p>
                Result: {formatCurrency(programObjective?.remaining_profit)}
              </p>
              {/* <h4 className={programObjective?.profit < 0 ? "loss" : programObjective?.profit > 0 ? "profit" : null}>Result ${programObjective?.remaining_profit}</h4> */}
            </div>
          </div>
          <p
            style={{ textTransform: "capitalize" }}
            className={
              programObjective?.profit_status === "in_progress"
                ? "status Pending_tag"
                : programObjective?.profit_status === "failed"
                ? "status Inactive_tag"
                : programObjective?.profit_status === "passed"
                ? "status Active_tag"
                : "status"
            }
          >
            {programObjective?.profit_status?.replace("_", " ")}
          </p>
        </div>

        <div
          className="revenuegrowth-innercontainer first"
          style={{
            display:
              New?.name === programObjective?.max_loss_status ||
              New?.name === "All"
                ? "flex"
                : "none",
          }}
        >
          <div style={{ flex: "1" }}>
            {programObjective?.max_loss_status === "in_progress" ? (
              <img src={ClockGoldIcon} alt="Clock Icon" />
            ) : programObjective?.max_loss_status === "failed" ? (
              <img src={WarningRedIcon} alt="Check Icon" />
            ) : (
              <img
                style={{ minWidth: "10px" }}
                src={CheckGreenIcon}
                alt="Check Icon"
              />
            )}
            <div style={{ display: "flex" }}>
              <h4>
                Max Loss: {formatCurrency(programObjective?.max_loss_target)}
              </h4>
              <p>
                Remaining:{" "}
                {formatCurrency(programObjective?.remaining_max_loss)}
              </p>
              <p>
                Threshold:{" "}
                {formatCurrency(programObjective?.max_loss_threshold)}
              </p>
              {/* <h4 className={programObjective?.max_loss < 0 ? "loss" : programObjective?.max_loss > 0 ? "profit" : null}>Result: ${programObjective?.remaining_max_loss}</h4> */}
            </div>
          </div>
          <p
            style={{ textTransform: "capitalize" }}
            className={
              programObjective?.max_loss_status === "in_progress"
                ? "status Pending_tag"
                : programObjective?.max_loss_status === "failed"
                ? "status Inactive_tag"
                : programObjective?.max_loss_status === "passed"
                ? "status Active_tag"
                : "status"
            }
          >
            {programObjective?.max_loss_status?.replace("_", " ")}
          </p>
        </div>

        <div
          className="revenuegrowth-innercontainer"
          style={{
            display:
              New?.name === programObjective?.trading_days_status ||
              New?.name === "All"
                ? "flex"
                : "none",
          }}
        >
          <div style={{ flex: "1" }}>
            {programObjective?.trading_days_status === "in_progress" ? (
              <img src={ClockGoldIcon} alt="Clock Icon" />
            ) : programObjective?.trading_days_status === "failed" ? (
              <img src={WarningRedIcon} alt="Check Icon" />
            ) : (
              <img
                style={{ minWidth: "10px" }}
                src={CheckGreenIcon}
                alt="Check Icon"
              />
            )}

            <div style={{ display: "flex" }}>
              <h4>
                {" "}
                Trading Days Target: {
                  programObjective?.trading_days_target
                }{" "}
                Days
              </h4>
              <p>
                Traded:{" "}
                {programObjective?.trading_days === 1
                  ? `${programObjective?.trading_days} Day`
                  : `${programObjective?.trading_days} Days`}
              </p>
              {/* <h4 className={programObjective?.max_daily_loss < 0 ? "loss" : programObjective?.max_daily_loss > 0 ? "profit" : null}>Result: ${programObjective?.remaining_daily_max_loss}</h4> */}
            </div>
          </div>
          <p
            style={{ textTransform: "capitalize" }}
            className={
              programObjective?.trading_days_status === "in_progress"
                ? "status Pending_tag"
                : programObjective?.trading_days_status === "failed"
                ? "Inactive_tag status"
                : programObjective?.trading_days_status === "passed"
                ? "Active_tag status"
                : "status"
            }
          >
            {programObjective?.trading_days_status?.replace("_", " ")}
          </p>
        </div>

        {/* <div
          className="revenuegrowth-innercontainer"
          style={{
            display:
              New?.name === programObjective?.profit_status ||
              New?.name === "All"
                ? "flex"
                : "none",
          }}
        >
          <div style={{ flex: "1" }}>
            {programObjective?.profit_status === "in_progress" ? (
              <img src={ClockGoldIcon} alt="Clock Icon" />
            ) : programObjective?.profit_status === "failed" ? (
              <img
                style={{ minWidth: "10px" }}
                src={WarningRedIcon}
                alt="Check Icon"
              />
            ) : (
              <img src={CheckGreenIcon} alt="Check Icon" />
            )}

            <div style={{ display: "flex" }}>
              <h4>Max daily loss Threshold: </h4>
            </div>
          </div>
          <h4>{formatCurrency(programObjective?.max_daily_loss_threshold)}</h4>
        </div> */}

        {/* <div
          className="revenuegrowth-innercontainer"
          style={{
            display: New?.name === programObjective?.trading_days_status || New?.name === "All" ? "flex" : "none",
          }}
        >
          <div>
            {programObjective?.trading_days_status === "in_progress" ? (
              <img
                src={ClockGoldIcon}
                alt="Clock Icon"
              />
            ) : programObjective?.trading_days_status === "failed" ? (
              <img
                src={WarningRedIcon}
                alt="Check Icon"
              />
            ) : (
              <img
                style={{minWidth: "10px"}}
                src={CheckGreenIcon}
                alt="Check Icon"
              />
            )}

            <div>
              <h4>{`Trading Days Status - ${programObjective?.trading_days_target}`}</h4>
              <p>{`Results : $${programObjective?.trading_days}`}</p>
            </div>
          </div>
          <p style={{textTransform:"capitalize"}}
            className={
              programObjective?.trading_days_status === "in_progress"
                ? "status Pending_tag"
                : programObjective?.trading_days_status === "failed"
                ? "status Inactive_tag"
                : programObjective?.trading_days_status === "passed"
                ? "status Active_tag"
                : "status"
            }
          >
            {programObjective?.trading_days_status === "passed" ? "Passed" : programObjective?.trading_days_status}
          </p>
        </div> */}
        {/* <div className="revenuegrowth-innercontainer">
          <div>
            <img src={WarningFullRedIcon} alt="Warning Icon" />
            <div>
              <h4>Max Daily Loss -$2,500</h4>
              <p>Results : $$480.46</p>
            </div>
          </div>
          <p className="status status-error">Error</p>
        </div> */}
        {/* <div className="revenuegrowth-innercontainer revenuegrowth-showall">
          <p>Show all</p>
          <img src={CaretDownIcon} alt="Caret Down" />
        </div> */}
      </div>
    </div>
  );
}

export default RevenueGrowth;
