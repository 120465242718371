import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTable } from "react-table";
import { baseUrl } from "../../utils/api/apis";
import EditIcon from "../../assets/icons/edit.svg";
import CalendarIcon from "../../assets/icons/calender.svg";
import Search from "../../assets/icons/search.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import CaretLeftIcon from "../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../assets/icons/caret-right.svg";
import Calendar from "../../reusableComponents/Calendar";
import CaretDownIcon from "../../assets/icons/caret-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon, getCouponData } from "../../store/reducers/couponSlice";
import { ReactComponent as SearchNew } from "../../assets/icons/search-svgrepo-com (1).svg";

import { Link, useNavigate, useNavigation } from "react-router-dom";
import SearchBar from "../SearchBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  changeUserStatus,
  deleteUser,
  getPaymentList,
  getPaymentListOLD,
  getUserList,
  resetPassword,
} from "../../utils/api/apis";
import { returnErrors } from "../../store/reducers/error";
import { setIsLoading } from "../../store/reducers/authSlice";
import moment from "moment";
import { sassNull } from "sass";
import Select from "react-select";
import { capitalizeFirstLetter, statusClassMap } from "../../utils/string";
import CountryFlag, { ReactCountryFlag } from "react-country-flag";
import { country } from "../../utils/constants/country";
import { HtmlTooltip } from "../../reusableComponents/Tooltip";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tooltip,
} from "@mui/material";
import { returnMessages } from "../../store/reducers/message";
import ConfirmationModal from "../../pages/FundingEvaluation/ConfirmationModal";
import { ToastContainer, toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getAccountListSuccess,
  setActiveAccount,
  setActiveUser,
} from "../../store/reducers/accountSlice";
import axios from "axios";

function UserListTable() {
  const navigate = useNavigate();
  const handleNavigateToOverView = async (value) => {
    // dispatch(setIsLoading(true));
  };
  const handleRowClick = (data) => {
    navigate("/traders-info/details", { state: { data } });
  };

  const [searchedUser, setSearchUser] = useState([]);
  const [activeTab, setactiveTab] = useState();
  const [active, setActive] = useState(true);
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [search, setSearch] = useState(null);
  const dropdownRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  const AuthType = [
    { label: "All", value: null },
    { label: "Email", value: "email" },
    { label: "Google", value: "google" },
  ];
  const statusOptions = [
    { label: "All", value: null },
    { label: "Open", value: "open" },
    { label: "Succeeded", value: "succeeded" },
  ];
  const [authT, setAuthT] = useState(AuthType[0]);
  const [status, setStatus] = useState(null);
  // let timeoutId;
  // const handleSearchChange = (e) => {
  //   clearTimeout(timeoutId);
  //   const value = e.target.value;
  //   timeoutId = setTimeout(() => {
  //     setSearch(value);
  //   }, 1000);
  // };
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    // if (searchValue) {
    //   setCurrentPage(1);
    // }
  };
  const inputRef = useRef(null);
  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };

  const handleActiveTab = (value) => {
    setActive((prevActive) => (prevActive === value ? null : value));
    setCurrentPage(1);
  };

  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] =
    useState(null);

  console.log("currentPage", currentPage);

  return (
    <div className="table_scroll_wrapper">
      <div className="alltable">
        <div className="alltable_header">
          {/* <div className="search_bar">
            <span
              onClick={handleResetClick}
              style={{ padding: "14px", color: "#1154ac", cursor: "pointer" }}
            >
              x
            </span>
            <input
              type="text"
              className="search_input"
              placeholder="Search..."
              ref={inputRef}
              onChange={handleSearchChange}
              // value={search}
              // onChange={(e) => {
              //   setSearch(e.target.value);
              //   delayedSearch(e.target.value);
              // }}
              // value={search}
              // onChange={(e) => {
              //   setSearch(e.target.value);
              //   delayedSearch(e.target.value);
              // }}
            />
          </div> */}
          <form
            className="search_container"
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(searchValue);
              setSearchValue(null);
              setCurrentPage(1);
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
            />
            <button type="submit">
              <div style={{ padding: "3px 10px 0 0" }}>
                <SearchNew style={{ width: "100%" }} />
              </div>
            </button>
          </form>
          <div className="sort_buttons">
            <div className="mobile_dropDowns">
              <Select
                placeholder="Auth Type"
                classNamePrefix="react-select"
                options={AuthType}
                value={authT}
                onChange={(selectedOption) => {
                  // console.log(selectedOption, "selectedOption");
                  selectedOption.value === null
                    ? setAuthT(null)
                    : setAuthT(selectedOption);
                }}
              />
            </div>
            <div
              className="desktop_pills"
              style={{ flex: "1", display: "flex", gap: "10px" }}
            >
              {AuthType.map((item) => (
                <button
                  onClick={() => setAuthT(item)}
                  className={authT?.value === item.value ? "activeBtn" : ""}
                >
                  {item.label}
                </button>
              ))}
            </div>
            <div className="line"></div>
            <div style={{ flex: "1", display: "flex", gap: "10px" }}>
              <button
                onClick={() => handleActiveTab(true)}
                className={active === true ? "activeBtn" : ""}
              >
                Active
              </button>

              <button
                onClick={() => handleActiveTab(false)}
                className={active === false ? "activeBtn" : ""}
              >
                Inactive
              </button>
            </div>
          </div>
        </div>
        {searchDropDown && (
          <div className="challenge_dropdown" ref={dropdownRef}>
            <ul>
              {searchedUser.map((user) => (
                <li
                  key={user.email}
                  style={{
                    position: "relative",
                    zIndex: "100",
                    backgroundColor: "#111142",
                  }}
                  onClick={() => {
                    handleNavigateToOverView(user.email);
                  }}
                >
                  {user.email}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="table_wrapper">
          <div className="table_scroll_wrapper">
            <Table
              search={search}
              searchValue={searchValue}
              authT={authT?.value}
              active={active}
              setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
              setDeleteConfirmationModalContent={
                setDeleteConfirmationModalContent
              }
              handleRowClick={handleRowClick}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>

      {isDeleteConfirmationModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteConfirmationModalOpen}
          closeModal={() => setDeleteConfirmationModalOpen(false)}
          deleteContent={deleteConfirmationModalContent}
        />
      )}
    </div>
  );
}

export default UserListTable;
const handleCopyToClipboard = (e, text) => {
  toast("Copied email", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "success",
    theme: "dark",
    className: "custom-toast-container",
  });
};
const Table = ({
  search,
  active,
  authT,
  handleRowClick,
  searchValue,
  currentPage,
  setCurrentPage,
}) => {
  const [filter, setFilter] = useState(null);
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  // const navigate = useNavigation();
  // const coupon = useSelector((state) => state.coupon.coupon);
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);

  const fetch = async () => {
    dispatch(setIsLoading(true));
    // return
    const response = await getUserList(
      idToken,
      search,
      active,
      authT,
      currentPage,
      searchValue
    );

    if (response?.status < 399) {
      setTableData(response?.data?.results);
      // const test = Math.ceil(response?.data?.count / 20);
      setTotalPages(Math.ceil(response?.data?.count / 21));
    } else {
      dispatch(returnErrors("Error getting User list"));
    }
    dispatch(setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, [idToken, filter, search, active, authT, currentPage]);

  // const data = tableData;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [code, setCode] = useState();

  const handleDelete = (couponCode) => {
    setCode(couponCode);
  };

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData =
    filter === "All"
      ? tableData
      : filter === true
      ? tableData.filter((item) => item.is_active === true)
      : tableData.filter((item) => item.is_active === false);

  // const handleActiveAccount = (row) => {
  //   row.original?.login_id
  //     ? dispatch(setActiveAccount(row.original.login_id))
  //     : dispatch(setActiveAccount(null));

  // };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "UID",
      //   accessor: "uid",
      //   Cell: ({ value }) => (
      //     <td
      //       style={{ maxWidth: "240px", overflow: "hidden" }}
      //       className="code"
      //     >
      //       {value}
      //     </td>
      //   ),
      // },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row, value }) => {
          const [randomColor, setRandomColor] = useState();
          const getRandomColor = `#${Math.floor(
            Math.random() * 16777215
          ).toString(16)}`;
          if (!randomColor) {
            setRandomColor(getRandomColor);
          }

          return (
            <>
              <div
                className="column_one_wrapper"
                // onClick={() => handleRowClick(row.original.email)}
              >
                <div
                  className="sno_wrapper"
                  style={{ backgroundColor: randomColor }}
                >
                  {value.charAt(0).toUpperCase()}
                </div>
                <div>{value}</div>
                <div onClick={(e) => e.stopPropagation()}>
                  <CopyToClipboard
                    onCopy={(e) => handleCopyToClipboard(e, value)}
                    text={value}
                  >
                    {/* <button> */}
                    <p
                      className="table_copy_button"
                      style={{
                        marginRight: "20px",
                        // backgroundColor: "#35d269",
                        cursor: "pointer",
                      }}
                      // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                    >
                      {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#FFF" />
                        <path
                          d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                          stroke="#36D66B"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                    {/* </button> */}
                  </CopyToClipboard>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <div style={{ cursor: "pointer" }}>
              {row.original.first_name
                ? row.original.first_name + " " + row.original.last_name
                : "-"}
            </div>
          );
        },
      },
      {
        Header: "Date joined",
        accessor: "date_joined",
        Cell: ({ value }) => (
          <td style={{ paddingRight: "20px" }}>{moment(value).format("ll")}</td>
        ),
      },
      {
        Header: "Auth type",
        accessor: "auth_type",
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      { Header: "Contact", accessor: "contact" },
      {
        Header: "Country",
        accessor: "country",
        Cell: ({ row, value }) => {
          const selectedCountry = country.find((c) => c.country === value);

          return (
            <div style={{ cursor: "pointer" }}>
              {" "}
              <HtmlTooltip
                title={selectedCountry?.country}
                placement="right"
                followCursor={true}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 20],
                        },
                      },
                    ],
                  },
                }}
                arrow
              >
                <div>
                  <ReactCountryFlag
                    countryCode={selectedCountry?.iso}
                    svg
                    style={{
                      width: "2em",
                      height: "2em",
                    }}
                    // title={value}
                    title={selectedCountry?.country || "N/A"}
                    aria-label={selectedCountry?.country || "N/A"}
                  />
                </div>
              </HtmlTooltip>{" "}
            </div>
          );
        },
      },

      // mahendra code

      // {
      //   Header: "Active",
      //   accessor: "active",
      //   Cell: ({ value }) => <td>{value ? "Active" : "blocked"}</td>,
      // },
      {
        Header: "Active",
        accessor: "is_active",
        Cell: ({ value }) => (
          <td>
            {
              <div
                className={`status_wrapper ${
                  value ? statusClassMap.succeeded : statusClassMap.expired
                }`}
              >
                {value ? "active" : "blocked"}
              </div>
            }
          </td>
        ),
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => {
          const navigate = useNavigate();
          const [userAccounts, setUserAccounts] = useState(false);
          const handleUserAccounts = async (row) => {
            let config = {
              headers: { Authorization: `Bearer ${idToken}` },
            };
            const userId = await axios
              .get(
                `${baseUrl}auth/admin/search-user/?search=${row.original.email}`,
                config
              )
              .then((res) => Array.isArray(res.data) && res?.data[0]?.id);

            const userAccountsList = await axios
              .get(
                `${baseUrl}account/admin/account-filter/?user_id=${userId}`,
                config
              )
              .then((res) => {
                return res?.data;
                // dispatch(getAccountListSuccess(res.data));
                // dispatch(setActiveAccount(res?.data[0]?.login_id));
              });
            setUserAccounts(userAccountsList);
          };

          const [anchorEl, setAnchorEl] = React.useState(null);
          const [confirmationModal, setConfirmationModal] =
            React.useState(false);
          const [confirmationModal1, setConfirmationModal1] =
            React.useState(false);

          const open = Boolean(anchorEl);
          const handleClick = (event) => {
            // setUserAccounts(false);
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };
          const dispatch = useDispatch();

          const [data, setData] = useState();
          const [email, setEmail] = useState();
          const handleStatus = async (e) => {
            setConfirmationModal(true);
            setData({ status: e.is_active ? "disable" : "enable" });
            setEmail(e.email);
          };
          const handleChangeStatus = async (e) => {
            const response = await changeUserStatus(idToken, email, data);
            if (response?.status < 399) {
              dispatch(
                returnMessages(response?.data?.detail, response?.status)
              );
            } else {
              let msg =
                response?.response?.data?.detail || "Something went wrong";
              dispatch(returnErrors(msg, 400));
            }
          };
          const handleResetPassword = async (e) => {
            const data = { email: e };
            const response = await resetPassword(idToken, data);
            if (response?.status < 399) {
              dispatch(
                returnMessages(response?.data?.detail, response?.status)
              );
            } else {
              let msg =
                response?.response?.data?.detail || "Something went wrong";
              dispatch(returnErrors(msg, 400));
            }
          };

          const handleDeleteUser = async (e) => {
            setConfirmationModal1(true);
            setEmail(e.email);
          };
          const handleDeleteUserConf = async () => {
            // const email = e?.email;
            const response = await deleteUser(idToken, email);

            if (response?.status < 399) {
              dispatch(
                returnMessages(response?.data?.detail, response?.status)
              );
            } else {
              let msg =
                response?.response?.data?.detail || "Something went wrong";
              dispatch(returnErrors(msg, 400));
            }
          };
          return (
            <div>
              {confirmationModal && (
                <ConfirmationModal
                  // id={selectedCompId}
                  setConfirmationModal={setConfirmationModal}
                  onConfirm={handleChangeStatus}
                />
              )}
              {confirmationModal1 && (
                <ConfirmationModal
                  // id={selectedCompId}
                  setConfirmationModal={setConfirmationModal1}
                  onConfirm={handleDeleteUserConf}
                />
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: "150px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {
                  <MenuItem disabled={userAccounts}>
                    {
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUserAccounts(row);
                        }}
                      >
                        {userAccounts.length === 0
                          ? "No Accounts"
                          : "View Metrics"}
                      </div>
                    }
                  </MenuItem>
                }
                {userAccounts && (
                  <div className="accounts_container">
                    {userAccounts?.map((item) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(setActiveUser(item));
                          dispatch(setActiveAccount(item.login_id));
                          navigate("/traders-list-2", {
                            state: { login_id: item?.login_id },
                          });
                        }}
                      >
                        {item?.login_id}
                      </MenuItem>
                    ))}
                  </div>
                )}

                <MenuItem onClick={() => handleStatus(row.original)}>
                  {row.original.is_active ? "Block" : "Activate"}
                </MenuItem>
                {row?.original?.auth_type === "email" ? (
                  <MenuItem
                    onClick={() => handleResetPassword(row.original.email)}
                  >
                    Reset Password
                  </MenuItem>
                ) : (
                  ""
                )}
                <MenuItem onClick={() => handleDeleteUser(row.original)}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
          );
        },
      },
    ],
    []
  );
  const [inputText, setInputText] = useState("");

  // const filteredDataCoupon = tableData.filter((el) => {
  //
  //   return el.code.toLowerCase().includes(inputText);
  // });
  //

  useEffect(() => {
    setPageNumbers([...Array(totalPages).keys()].map((i) => i + 1));
  }, [totalPages]);

  const handlePagination = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };

  return (
    <div className="table_wrapper">
      <ToastContainer />
      <div style={{ marginTop: "20px" }} className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const handleClick = () => {
                      if (cell.column.Header !== "Action") {
                        handleRowClick(row.original);
                      }
                    };

                    return (
                      <td
                        className={`${
                          cell.column.Header === "Status" ? "status_column" : ""
                        }`}
                        onClick={handleClick}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>{" "}
      </div>

      <div className="spacerT30p"></div>
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Pagination
          onChange={handleChange}
          count={totalPages}
          variant="outlined"
          page={currentPage}
          color="primary"
        />
        <div className="goto-page-input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteModal = ({
  isModalOpen,
  closeModal,
  deleteContent,
  onConfirmDelete,
  idToken,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteCoupon({ idToken: idToken, code: deleteContent }));
    closeModal();
  };

  return (
    <div className="modal">
      <div className="modal_content">
        <p>Are you sure you want to delete?</p>
        <div className="modal_btn">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={closeModal}>No</button>
        </div>
      </div>
    </div>
  );
};
