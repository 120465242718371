import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon, getCouponData } from "../../store/reducers/couponSlice";
import { setIsLoading } from "../../store/reducers/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-svgrepo-com (1).svg";
import CreateIcon from "../../assets/icons/plus-circle-blue.svg";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import axios from "axios";
import { getCouponDetails } from "../../utils/api/apis";
import { usePagination, useTable } from "react-table";
import { Pagination } from "@mui/material";

function CouponTable() {
  const idToken = useSelector((state) => state.auth.idToken);
  const [filteredCoupon, setFilteredCoupon] = useState(null);
  const [inputText, setInputText] = useState("");
  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] =
    useState(null);
  const [params, seParams] = useState({
    page: 1,
    page_size: 15,
  });
  const fetchCouponsList = async () => {
    try {
      const response = await getCouponDetails(idToken, params);
      setFilteredCoupon(response); // Update the state with the fetched coupon data
    } catch (error) {
      console.error("Failed to fetch coupon details:", error);
    }
  };

  useEffect(() => {
    fetchCouponsList();
  }, []);
  console.log(filteredCoupon, "filteredCoupon");
  return (
    <div className="coupon_table">
      <div className="table_scroll_wrapper">
        <Table
          inputText={inputText}
          setInputText={setInputText}
          coupon={filteredCoupon?.results || []}
          setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
          setDeleteConfirmationModalContent={setDeleteConfirmationModalContent}
        />
        {isDeleteConfirmationModalOpen && (
          <DeleteModal
            isModalOpen={isDeleteConfirmationModalOpen}
            closeModal={() => setDeleteConfirmationModalOpen(false)}
            deleteContent={deleteConfirmationModalContent}
            idToken={idToken}
          />
        )}
      </div>
    </div>
  );
}

export default CouponTable;

// Table Component
const Table = ({ inputText, setInputText, coupon, onDelete }) => {
  const navigate = useNavigate()
  const columns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Coupon %",
        accessor: "percent",
      },
      {
        Header: "Coupon Expiry",
        accessor: "expiry",
      },
      {
        Header: "Status",
        accessor: "is_active",
        Cell: ({ value }) => (
          <button className={value ? "Active_tag" : "Inactive_tag"}>
            {value ? "Active" : "Inactive"}
          </button>
        ),
      },
      {
        Header: "Public",
        accessor: "public",
        Cell: ({ value }) => (
          <button className={value ? "Active_tag" : "Inactive_tag"}>
            {value ? "True" : "False"}
          </button>
        ),
      },
      {
        Header: "",
        accessor: "delete",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "14px", alignItems: "center" }}>
            <img
              onClick={() => navigate(`/coupons/create-coupon/${row.original.id}`, { state: row.original })}
              src={EditIcon}
              alt="Edit"
              style={{ cursor: "pointer", height: "20px" }}
            />
            <img
              onClick={() => onDelete(row.original.code)}
              src={DeleteIcon}
              alt="Delete"
              style={{ cursor: "pointer", height: "20px" }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: coupon,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className="table_wrapper">
      <div className="table_header">
        <form
          className="search_container"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
          <button type="submit">
            <SearchIcon style={{ width: "20px", padding: "3px 10px 0 0" }} />
          </button>
        </form>
        <Link to="/coupons/create-coupon">
          <div className="create_wrapper">
            <img className="create_icon" src={CreateIcon} alt="Create" />
            <p>Create Coupon</p>
          </div>
        </Link>
      </div>
      <div className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <Pagination
          count={Math.ceil(coupon.length / pageSize)} // Total pages
          page={pageIndex + 1} // Current page (react-table uses 0-based index)
          onChange={(_, page) => gotoPage(page - 1)} // Material-UI Pagination is 1-based
          variant="outlined"
          color="primary"
          showFirstButton
          showLastButton
        />
      </div>
    </div>
  );
};

// DeleteModal Component
const DeleteModal = ({ isModalOpen, closeModal, deleteContent, idToken }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteCoupon({ idToken, code: deleteContent }));
    closeModal();
  };

  return (
    <div className="confirmationModal_wrapper">
      <h2>Are you sure you want to delete this coupon?</h2>
      <div className="buttons_wrapper">
        <button className="confirm" onClick={handleDelete}>
          Confirm
        </button>
        <button className="cancel" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </div>
  );
};
