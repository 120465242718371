import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

import EmailIcon from "../../../assets/icons/email.svg";
import LockIcon from "../../../assets/icons/lock.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashedIcon from "../../../assets/icons/eye-slashed.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import GoogleIcon from "../../../assets/icons/google.svg";
import FacebookIcon from "../../../assets/icons/facebook.svg";
import GithubIcon from "../../../assets/icons/github.svg";

import { Link, useNavigate } from "react-router-dom";
import { validateEmail, validatePassword } from "../../../utils/helpers/string";
import Tooltip from "../../../reusableComponents/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from "../../../store/reducers/authSlice";

function SigninForm() {
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const [inputError, setInputError] = useState({ email: "", password: "" });
  const [isInputError, setIsInputError] = useState({
    email: false,
    password: false,
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let emailRef = useRef(null);
  let passwordRef = useRef(null);

  const {isAuthenticated, custom_permissions} = useSelector((state) => state.auth);
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideBarList= [
    { adminAccess: custom_permissions?.includes("risk_management"), path: "/risk-management" },
    { adminAccess: custom_permissions?.includes("trader_list"), path: "/super-trader-list/" },
    { adminAccess: custom_permissions?.includes("stage_manager"), path: "/stage-management" },
    { adminAccess: custom_permissions?.includes("user_management"), path: "/user-list" },
    { adminAccess: custom_permissions?.includes("account_creation"), path: "/funding-evaluation" },
    { adminAccess: custom_permissions?.includes("certificates"), path: "/CertificateManagement" },
    { adminAccess: custom_permissions?.includes("payments"), path: "/paymentlist" },
    { adminAccess: custom_permissions?.includes("payout"), path: "/payout_list" },
    { adminAccess: custom_permissions?.includes("coupons"), path: "/coupons" },
    { adminAccess: custom_permissions?.includes("affiliate_market"), path: "/affiliate-marketing" },
    { adminAccess: custom_permissions?.includes("admin_support"), path: "/superadmin" },
    { adminAccess: custom_permissions?.includes("ip_log"), path: "/ip-log" }
  ];
  
  useEffect(() => {
    const firstAdminPath = sideBarList.find((item) => item.adminAccess)?.path;

    if (isAuthenticated) {
      navigate(firstAdminPath);
    }
  }, [isAuthenticated]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (e) => {
    const { id } = e.target;
    id === "email" && emailRef.current.classList.remove("input-error");
    id === "password" && passwordRef.current.classList.remove("input-error");
  };

  const handleFocus = (e) => {
    const { id } = e.target;
    setIsInputError((prev) => ({ ...prev, [id]: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = inputValue;

    dispatch(authenticate(inputValue));

    if (email === "") {
      setInputError((prev) => ({
        ...prev,
        email: "Please enter the email!",
      }));
    } else if (!validateEmail(email)) {
      setInputError((prev) => ({
        ...prev,
        email: "The entered email is wrong!",
      }));
    }
    if (password === "") {
      setInputError((prev) => ({
        ...prev,
        password: "Please enter the password!",
      }));
    } else if (!validatePassword(password)) {
      setInputError((prev) => ({
        ...prev,
        // password: "The entered password is wrong!",
        password: "Password must be atleast 8 characters",
      }));
    }

    if (email === "" || !validateEmail(email)) {
      emailRef.current.classList.add("input-error");
      setIsInputError((prev) => ({ ...prev, email: true }));
    }
    if (password === "" || !validatePassword(password)) {
      passwordRef.current.classList.add("input-error");
      setIsInputError((prev) => ({ ...prev, password: true }));
    }

    try {
      //
    } catch (err) {}
  };

  return (
    <div className="signinform-container">
      <div className="signinform-subcontainer">
        <h2 className="signinform-title">Sign In</h2>
        <p className="signinform-subtitle">Welcome Back! Good to See you!</p>

        <form className="signinform-innercontainer signinform-container_form">
          <div className="signinform-subcontainer_form">
            <div ref={emailRef} className="signinform-innercontainer_form">
              <div className="signinform-container_icon">
                <img src={EmailIcon} alt="" className="signinform-icon" />
              </div>
              <div className="signinform-container_input">
                <label className="signinform-label" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="signinform-input"
                  value={inputValue.email}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </div>

              <div className="signinform-container_icon">
                {validateEmail(inputValue.email) && (
                  <img
                    src={CheckSolidGreenIcon}
                    alt=""
                    className="signinform-icon"
                  />
                )}
              </div>
            </div>
            <Tooltip isActive={isInputError.email}>{inputError.email}</Tooltip>
          </div>
          <div className="signinform-subcontainer_form">
            <div ref={passwordRef} className="signinform-innercontainer_form">
              <div className="signinform-container_icon">
                <img src={LockIcon} alt="" className="signinform-icon" />
              </div>
              <div className="signinform-container_input">
                <label className="signinform-label" htmlFor="password">
                  Password
                </label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  id="password"
                  className="signinform-input"
                  value={inputValue.password}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </div>

              <div className="signinform-container_icon">
                <img
                  src={isPasswordVisible ? EyeIcon : EyeSlashedIcon}
                  alt=""
                  className="signinform-icon"
                  onClick={() => setIsPasswordVisible((prev) => !prev)}
                />
              </div>
            </div>
            <Tooltip isActive={isInputError.password}>
              {inputError.password}
            </Tooltip>
          </div>
          {/* <p className="signinform-error">The password entered is wrong!</p> */}

          {/* <div className="signinform-subcontainer_form signinform-container_remember-me">
            <div className="signinform_subcontainer_wrapper">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                className="signinform-input"
              />
              <label
                className="signinform-label signinform-label_checkbox"
                htmlFor="checkbox"
              >
                Remember me
              </label>
            </div>
          </div> */}
          <div className="signinform-container_button">
            <button className="signinform-button" onClick={handleSubmit}>
              Continue
              <img src={ArrowRightIcon} alt="" className="signinform-icon" />
            </button>
          </div>
        </form>
        {/* <p className="signinform-continue">or continue with</p>
        <div className="signinform-innercontainer signinform-container_icon">
          <div className="signinform-subcontainer_icon">
            <img src={GoogleIcon} alt="" className="signinform-icon" />
          </div>
          <div className="signinform-subcontainer_icon">
            <img src={GithubIcon} alt="" className="signinform-icon" />
          </div>
          <div className="signinform-subcontainer_icon">
            <img src={FacebookIcon} alt="" className="signinform-icon" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default SigninForm;
