import { Pagination } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, useTable } from "react-table";
import Tabination from "../../reusableComponents/Tabination";
import { setIsLoading } from "../../store/reducers/authSlice";
import { returnErrors } from "../../store/reducers/error";
import { getRiskManagement, getUserList } from "../../utils/api/apis";
import "./style.scss";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import moment from "moment";
import StageStatistics from "../../components/AdminOverview/StageStatistics";
import { formatCurrency } from "../../utils/helpers/string";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import dayjs from "dayjs";
import Select from "react-select";
import { country } from "../../utils/constants/country";
import { statusClassMap, statusToTag } from "../../utils/string";
import ApexChart from "../../components/AdminOverview/PieChart/Index";

function AdminOverview() {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();

  const [riskData, setRiskData] = useState({});
  const [usersData, setUsersData] = useState({});
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [searchValue, setSearchValue] = useState({
    start_date: null,
    end_date: null,
  });
  const fetchRiskData = async () => {
    dispatch(setIsLoading(true));

    try {
      const response = await getRiskManagement(idToken, searchValue);
      console.log(response, "Risk Management API Response");

      const userData = await getUserList(idToken, "", null);
      console.log(userData, "User List API Response");

      setUsersData(userData.data);

      if (response?.status < 399) {
        const riskManagementData = response.data.risk_management || {};
        console.log(riskManagementData, "Risk Management Data");

        setRiskData(riskManagementData);
      } else {
        dispatch(returnErrors("Error getting risk management data"));
      }
    } catch (error) {
      console.error("Error fetching data", error);
      dispatch(returnErrors("Error fetching data"));
    }

    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetchRiskData();
  }, [idToken, searchValue]);

  const columns = useMemo(
    () => [
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "No of Payments",
        accessor: "no_of_payments",
      },
      // {
      //   Header: "Date",
      //   accessor: "day",
      // },
      {
        Header: "Percent Payments",
        accessor: "percent_payments",
        Cell: ({ value }) => <div>{value?.toFixed(2)}</div>,
      },
      {
        Header: "Total Payments",
        accessor: "total_payments",
      },
    ],
    []
  );
  const columnsForPayout = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => <div>{moment(value).format("DD-MM-YYYY")}</div>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <div className={statusToTag(value)}>{value}</div>,
      },
      {
        Header: "Payout Method",
        accessor: "payout_metohd",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Payout Amount",
        accessor: "payout_amount",
      },
    ],
    []
  );
  const columnsForRevenue = useMemo(
    () => [
      {
        Header: "Total Profit",
        accessor: "total_profit",
        Cell: ({ value }) => (
          <div
            style={{ color: value > 0 ? "#4cb944" : value < 0 && "#ff5733" }}
          >
            {formatCurrency(value)}
          </div>
        ),
      },
      {
        Header: "Total Revenue",
        accessor: "total_revenue",
        Cell: ({ value }) => <div>{formatCurrency(value)}</div>,
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => <div>{moment(value).format("DD-MM-YYYY")}</div>,
      },
      {
        Header: "Total Payout Requested",
        accessor: "total_payout_requested",
        Cell: ({ value }) => <div>{formatCurrency(value)}</div>,
      },
      {
        Header: "Total Payout Approved",
        accessor: "total_payout_approved",
        Cell: ({ value }) => <div>{formatCurrency(value)}</div>,
      },
      {
        Header: "Total No Accounts",
        accessor: "total_no_accounts",
      },
      // {
      //   Header: "Profit Split",
      //   accessor: "profit_split",
      // },
      {
        Header: "Revenue from Payments",
        accessor: "revenue_from_payments",
        Cell: ({ value }) => <div>{formatCurrency(value)}</div>,
      },
    ],
    []
  );

  console.log(
    riskData?.revenue_management?.revenue_management,
    "Countrywise Stats Data"
  );

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const items = [
    {
      name: "Overview",
      path: "overview",
    },
    {
      name: "1 Step",
      path: "step_1",
    },
    {
      name: "2 Step",
      path: "step_2",
    },
    {
      name: "Countrywise Stats",
      path: "countrywise",
    },
    {
      name: "Payout Lists",
      path: "payout-lists",
    },
    {
      name: "Revenue Management",
      path: "revenue-management",
    },
  ];

  useEffect(() => {
    const currentPath = location.pathname;

    switch (currentPath) {
      case "/risk-management/overview":
        setActiveTab(0);
        break;
      case "/risk-management/step_1":
        setActiveTab(1);
        break;
      case "/risk-management/step_2":
        setActiveTab(2);
        break;
      case "/risk-management/countrywise":
        setActiveTab(3);
        break;
      case "/risk-management/payout-lists":
        setActiveTab(4);
        break;
      case "/risk-management/revenue-management":
        setActiveTab(5);
        break;
    }
  }, [location.pathname]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start_date, end_date] = dates;
      setSearchValue({
        start_date: start_date ? dayjs(start_date).format("YYYY-MM-DD") : null,
        end_date: end_date ? dayjs(end_date).format("YYYY-MM-DD") : null,
      });
      setSelectedDates(dates); // Update the selected dates in state
    }
  };

  const handleReset = () => {
    setSelectedDates([null, null]); // Reset the selected dates
    setSearchValue({
      start_date: null,
      end_date: null,
    });
  };
  const [bodyClassName, setBodyClassName] = useState("");

  useEffect(() => {
    const updateBodyClassName = () => {
      const currentClassName = document.body.className;
      setBodyClassName(currentClassName);
    };

    updateBodyClassName();

    const observer = new MutationObserver(updateBodyClassName);

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const [activeTheme, setActiveTheme] = useState("");

  useEffect(() => {
    if (bodyClassName !== "dark-theme") {
      setActiveTheme("dark-theme");
    } else {
      setActiveTheme("light-theme");
    }
  }, [bodyClassName]);
  return (
    <>
      <Tabination
        variant={3}
        tabItems={items}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="support_wrapper support">
        <Tabination
          variant={6}
          showMobTabs={true}
          tabItems={items}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />{" "}
        <div className="papercon funded datepicker">
          <div
            className="col1_bottom_lower"
            style={{
              display: "flex",
              gap: "20px",
              width: "100%",
              maxWidth: "600px",
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <DateRangePicker
              className="datepicker-date"
              character=" – "
              placeholder="DD/MM/YYYY – DD/MM/YYYY"
              style={{
                width: "100%",
                maxWidth: "350px", // Restrict to 300px on larger screens
              }}
              onChange={handleDateChange}
              value={selectedDates}
            />
            <button
              className="reset_btn"
              style={{
                width: "100px",
                flexShrink: 0,
              }}
              onClick={handleReset}
            >
              Clear
            </button>
          </div>
        </div>
        <br />
        <Routes>
          <Route path="/" element={<Navigate to="overview" replace={true} />} />

          <Route
            path="overview"
            element={<OverviewSection data={(riskData && riskData) || {}} />}
          />
          <Route
            path="countrywise"
            element={
              <>
                <Table
                  tableFilter={
                    <div className="papercon">
                      <div className="col1_bottom_lower">
                        <p style={{ margin: "0px 0px 5px 5px" }} htmlFor="">
                          Search Country
                        </p>
                        <Select
                          placeholder="Search for a country"
                          // styles={customStyles}
                          isMulti
                          className="react-select-country"
                          options={country.map((items) => ({
                            label: items.country,
                            value: items.country,
                          }))}
                          onChange={(selectedOptions) => {
                            const selectedCountries = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setSearchValue((pre) => ({
                              ...pre,
                              country: selectedCountries,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  }
                  data={riskData?.countrywise_stats || []}
                  columns={columns}
                />
              </>
            }
          />
          <Route
            path="payout-lists"
            element={
              <>
                <div className="papercon funded rowOne payouts">
                  <div>
                    <div className="HeadT">Total Approved Payouts</div>
                    <div className="TextB">
                      {formatCurrency(riskData?.payout_stats?.approved || 0)}
                    </div>
                  </div>
                  <div>
                    <div className="HeadT">Total Pending Payouts</div>
                    <div className="TextB">
                      {formatCurrency(riskData?.payout_stats?.new || 0)}
                    </div>
                  </div>
                  <div>
                    <div className="HeadT">Total Rejected Payouts</div>
                    <div className="TextB">
                      {formatCurrency(riskData?.payout_stats?.rejected || 0)}
                    </div>
                  </div>
                  <div>
                    <div className="HeadT">Total Approved count</div>
                    <div className="TextB">
                      {riskData?.payout_stats?.approved_count || 0}
                    </div>
                  </div>
                  <div>
                    <div className="HeadT">Total Pending Count</div>
                    <div className="TextB">
                      {riskData?.payout_stats?.pending_count || 0}
                    </div>
                  </div>
                  <div>
                    <div className="HeadT">Total Rejected Count</div>
                    <div className="TextB">
                      {riskData?.payout_stats?.rejected_count || 0}
                    </div>
                  </div>
                  <div>
                    <div className="HeadT">Total Payouts Count</div>
                    <div className="TextB">
                      {riskData?.payout_stats?.total_payouts || 0}
                    </div>
                  </div>
                </div>

                <br />
                <Table
                  tableFilter={
                    <div className="papercon">
                      <div
                        className="col1_bottom_lower"
                        style={{ display: "flex", gap: "20px" }}
                      >
                        <div>
                          <p style={{ margin: "0px 0px 5px 5px" }} htmlFor="">
                            Search Email
                          </p>
                          <input
                            type="text"
                            id="country"
                            className="search_input"
                            placeholder="Search by email"
                            onKeyDown={(e) => {
                              let value = e.target.value;
                              if (e.key === "Enter") {
                                setSearchValue((pre) => ({
                                  ...pre,
                                  email: value,
                                }));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  data={riskData?.revenue_management?.payment_payout_list || []}
                  columns={columnsForPayout}
                />
              </>
            }
          />
          <Route
            path="revenue-management"
            element={
              <>
                <div className="papercon funded rowOne payouts">
                  <div>
                    <div className="HeadT">Total Revenue</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.total_revenue || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Successful Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.succeeded_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Expired Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.expired_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Pending Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.open_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Filtered Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.filtered_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Unpaid Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.unpaid_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Declined Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.declined_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Processing Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.processing_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Approved Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.approved_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Created Payments Amount</div>
                    <div className="TextB">
                      {formatCurrency(
                        riskData?.payment_stats?.created_amount || 0
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Succeeded Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.succeeded || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Expired Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.expired || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Pending Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.open || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Filtered Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.filtered || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Unpaid Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.unpaid || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Declined Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.declined || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Processing Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.processing || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Approved Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.approved || 0}
                    </div>
                  </div>

                  <div>
                    <div className="HeadT">Created Payments</div>
                    <div className="TextB">
                      {riskData?.payment_stats?.created || 0}
                    </div>
                  </div>
                </div>

                <br />
                <div className="overview_section">
                  <div className="papercon">
                    <div className="col1_bottom_lower">
                      <StageStatistics
                        title="Revenue Management"
                        seriesData={[
                          {
                            name: "Revenue",
                            data:
                              (riskData &&
                                riskData?.revenue_management?.revenue_management?.map(
                                  (item) => item.revenue_from_payments
                                )) ||
                              [],
                          },
                        ]}
                        categories={riskData?.revenue_management?.revenue_management?.map(
                          (item) => item.date.trim() || []
                        )}
                        colors={["#4CB944", "#FF5733"]}
                        // chartType="bar"
                        sidebarType={"Revenue"}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <Table
                  data={riskData?.revenue_management?.revenue_management || []}
                  columns={columnsForRevenue}
                />
              </>
            }
          />
          <Route
            path="step_1"
            element={<StepOne data={riskData?.risk_managemen_stats?.step1} />}
          />
          <Route
            path="step_2"
            element={<StepTwo data={riskData?.risk_managemen_stats?.step2} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default AdminOverview;

const Table = ({ data, columns, tableFilter }) => {
  const [gotoPageInput, setGotoPageInput] = useState("");

  // Define the number of items per page
  const itemsPerPage = 15;

  // Use react-table hooks with pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of rows, use page for paginated data
    gotoPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: itemsPerPage }, // Set initial page size
    },
    usePagination
  );

  const handlePageChange = (event, newPage) => {
    gotoPage(newPage - 1); // Pagination component uses 1-based index
  };

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10) - 1; // Convert to 0-based index
    if (
      !isNaN(pageNumber) &&
      pageNumber >= 0 &&
      pageNumber < pageOptions.length
    ) {
      gotoPage(pageNumber);
      setGotoPageInput("");
    } else {
      alert("Please enter a valid page number.");
    }
  };

  return (
    <div className="traderslist" style={{ backgroundColor: "#fff" }}>
      {tableFilter}
      <div className="table_scroll">
        <div className="alltable" style={{ padding: "0px" }}>
          <div className="table_wrapper">
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()} key={column.id}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} key={cell.column.id}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="spacerT30p"></div>
          <div
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
            className="pagination_container"
          >
            <Pagination
              count={pageOptions.length}
              variant="outlined"
              color="primary"
              page={pageIndex + 1} // Convert to 1-based index for the UI
              onChange={handlePageChange}
              defaultPage={1}
              siblingCount={0}
              boundaryCount={1}
            />
            <div className="goto-page-input">
              <input
                type="text"
                placeholder="Go to Page"
                value={gotoPageInput}
                onChange={(e) => setGotoPageInput(e.target.value)}
              />
              <button className="goToButton" onClick={handleGotoPage}>
                Go
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OverviewSection = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Start Date",
        accessor: "start_date", // maps to start_date in data
        width: 100,
      },
      {
        Header: "Profit/Loss",
        accessor: "p_n", // maps to p_n in data
        width: 100,
        Cell: ({ value }) => (
          <div
            style={{
              color: value === "profit" ? "#4cb944" : "#ff5733",
              textTransform: "capitalize",
            }}
          >
            {value}
          </div>
        ), // Optional, to display capitalized text
      },
      {
        Header: "P&L Amount",
        accessor: "pnl", // maps to pnl in data
        width: 100,
        Cell: ({ value }) => (
          <div
            style={{ color: value > 0 ? "#4cb944" : value < 0 && "#ff5733" }}
          >
            {formatCurrency(value)}
          </div>
        ), // Formats P&L to currency
      },
      {
        Header: "Starting Balance",
        accessor: "starting_balance", // maps to starting_balance in data
        width: 100,
        Cell: ({ value }) => formatCurrency(value), // Adds commas for large numbers
      },
    ],
    []
  );
  return (
    <div className="overview_section">
      <div className="papercon funded rowOne">
        <div className="funded_section">
          <div className="HeadT"> Funded Loss</div>
          <div
            className="TextB"
            style={{
              color:
                data?.funded_loss < 0
                  ? "#ff5733"
                  : data?.funded_loss > 0 && "#4cb944",
            }}
          >
            {formatCurrency(data?.funded_loss || 0)}
          </div>
        </div>

        <div>
          <div className="HeadT">Funded Profit</div>
          <div className="TextB" style={{ color: "#4cb944" }}>
            {formatCurrency(data?.funded_profit || 0)}
          </div>
        </div>

        <div>
          <div className="HeadT">Funded Total</div>
          <div className="TextB">{formatCurrency(data?.funded_total || 0)}</div>
        </div>

        <div>
          <div className="HeadT">Funded PNL</div>
          <div
            className="TextB"
            style={{
              color:
                data?.funded_pnl_total < 0
                  ? "#ff5733"
                  : data?.funded_pnl_total > 0 && "#4cb944",
            }}
          >
            {formatCurrency(data?.funded_pnl_total || 0)}
          </div>
        </div>

        <div className="funded_section">
          <div className="HeadT">Coin Payments</div>
          <div className="TextB">
            {formatCurrency(data?.revenue_growth?.coinpayments || 0)}
          </div>
        </div>

        <div>
          <div className="HeadT">Stripe</div>
          <div className="TextB">
            {formatCurrency(data?.revenue_growth?.stripe || 0)}
          </div>
        </div>

        <div>
          <div className="HeadT">Fibonatix</div>
          <div className="TextB">
            {formatCurrency(data?.revenue_growth?.fibonatix || 0)}
          </div>
        </div>

        <div>
          <div className="HeadT">PayPal</div>
          <div className="TextB">
            {formatCurrency(data?.revenue_growth?.paypal || 0)}
          </div>
        </div>

        <div>
          <div className="HeadT">Rapyd</div>
          <div className="TextB">
            {formatCurrency(data?.revenue_growth?.rapyd || 0)}
          </div>
        </div>
      </div>

      <div className="papercon">
        <div className="col1_bottom_lower">
          <StageStatistics
            title="Payouts Requested & Approved"
            seriesData={[
              {
                name: "Payout Approved",
                data:
                  (data &&
                    data?.revenue_management?.payment_requested_approved?.map(
                      (item) => item.payout_approved
                    )) ||
                  [],
              },
              {
                name: "Payout Requested",
                data:
                  (data &&
                    data?.revenue_management?.payment_requested_approved?.map(
                      (item) => item.payout_requested
                    )) ||
                  [],
              },
            ]}
            categories={data?.revenue_management?.payment_requested_approved?.map(
              (item) => item?.date || []
            )}
            colors={["#4CB944", "#FF5733"]} // Optional: pass different colors
            chartType="bar"
            sidebarType={"Amount (USD)"}
            bottomType={"Dates"}
            yAxisType={"USD"}
          />
        </div>
      </div>
      <div className="papercon">
        <div className="col1_bottom_lower">
          <StageStatistics
            title="Funded Accounts"
            seriesData={[
              {
                name: "Count",
                data:
                  (data &&
                    data?.revenue_management?.funded_accounts?.map(
                      (item) => item.count
                    )) ||
                  [],
              },
            ]}
            categories={data?.revenue_management?.funded_accounts?.map(
              (item) => item.month.trim() || []
            )}
            colors={["#4CB944", "#FF5733"]} // Optional: pass different colors
            chartType="bar"
            sidebarType={"Count"}
            bottomType={"Month"}
          />
        </div>
      </div>
      <Table
        data={data?.funded_data || []}
        columns={columns}
        tableFilter={<h3 className="chart_title">Funded Data List</h3>}
      />
    </div>
  );
};

const StepOne = ({ data }) => {
  console.log(data, "datadata");
  return (
    <>
      <div className="overview_section">
      <div className="papercon funded rowOne payouts">
         
          <ApexChart
            series={(data && Object.values(data?.step1_stage1_stats)) || []}
            labels={
              (data &&
                Object.keys(data?.step1_stage1_stats)?.map((key) => {
                  return key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase());
                })) ||
              []
            }
            chartHeading="Stage 1 Stats"
            colors={["#4CB944", "#FF5733", "#FFD700", "#58bdff"]}
          />

          <ApexChart
            series={(data && Object.values(data?.step1_funded_stats)) || []}
            labels={
              (data &&
                Object.keys(data?.step1_funded_stats)?.map((key) => {
                  return key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase());
                })) ||
              []
            }
            chartHeading="Funded Stats"
            colors={["#FFD700", "#FF5733", "#58bdff"]}
          />
        </div>
        <div className="papercon">
          <div className="col1_bottom_lower">
            <StageStatistics
              title="Risk Management Stats (Stage 1)"
              seriesData={[
                {
                  name: "Total Passed",
                  data:
                    (data &&
                      data?.step1_stage1?.map((item) => item.total_passed)) ||
                    [],
                },
                {
                  name: "Total Failed",
                  data:
                    (data &&
                      data?.step1_stage1?.map((item) => item.total_failed)) ||
                    [],
                },
              ]}
              categories={data?.step1_stage1?.map(
                (item) => item?.day?.trim() || []
              )}
              colors={["#4CB944", "#FF5733"]} // Optional: pass different colors
            />
          </div>
        </div>
        <div className="papercon">
          <div className="col1_bottom_lower">
            <StageStatistics
              title="Risk Management Stats (Funded)"
              seriesData={[
                {
                  name: "Total Failed",
                  data:
                    (data &&
                      data?.step1_funded?.map((item) => item.total_failed)) ||
                    [],
                },
                {
                  name: "Total In Progress",
                  data:
                    (data &&
                      data?.step1_funded?.map(
                        (item) => item.total_in_progress
                      )) ||
                    [],
                },
              ]}
              categories={data?.step1_funded?.map(
                (item) => item.start_date.trim() || []
              )}
              colors={["#FF5733", "#ffca28"]} // Optional: pass different colors
            />
          </div>
        </div>
        <div className="papercon">
          <div className="col1_bottom_lower">
            <StageStatistics
              title="Risk Management Stats (Funded PNL)"
              seriesData={[
                {
                  name: "Funded Profit",
                  data:
                    (data &&
                      data?.step1_funded_pnl?.map(
                        (item) => item.funded_profit
                      )) ||
                    [],
                },
                {
                  name: "Funded Loss",
                  data:
                    (data &&
                      data?.step1_funded_pnl?.map(
                        (item) => item.funded_loss
                      )) ||
                    [],
                },
              ]}
              categories={data?.step1_funded_pnl?.map(
                (item) => item.date.trim() || []
              )}
              colors={["#4CB944", "#FF5733"]} // Optional: pass different colors
            />
          </div>
        </div>
      </div>
    </>
  );
};
const StepTwo = ({ data }) => {
  console.log(data, "datadata");
  return (
    <>
      <div className="overview_section">
      <div className="papercon funded rowOne payouts">
        
          <ApexChart
            series={(data && Object.values(data?.step2_stage1_stats)) || []}
            labels={
              (data &&
                Object.keys(data?.step2_stage1_stats)?.map((key) => {
                  return key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase());
                })) ||
              []
            }
            chartHeading="Stage 1 Stats"
            colors={["#4CB944", "#FF5733", "#FFD700", "#58bdff"]}
          />
          <ApexChart
            series={(data && Object.values(data?.step2_stage2_stats)) || []}
            labels={
              (data &&
                Object.keys(data?.step2_stage2_stats)?.map((key) => {
                  return key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase());
                })) ||
              []
            }
            chartHeading="Stage 2 Stats"
            colors={["#4CB944", "#FF5733", "#FFD700", "#58bdff"]}
          />
          <ApexChart
            series={(data && Object.values(data?.step2_funded_stats)) || []}
            labels={
              (data &&
                Object.keys(data?.step2_funded_stats)?.map((key) => {
                  return key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase());
                })) ||
              []
            }
            chartHeading="Funded Stats"
            colors={["#FFD700", "#FF5733", "#58bdff"]}
          />
        </div>
        <div className="papercon">
          <div className="col1_bottom_lower">
            <StageStatistics
              title="Risk Management Stats (Stage 1)"
              seriesData={[
                {
                  name: "Total Passed",
                  data:
                    (data &&
                      data?.step2_stage1?.map((item) => item.total_passed)) ||
                    [],
                },
                {
                  name: "Total Failed",
                  data:
                    (data &&
                      data?.step2_stage1?.map((item) => item.total_failed)) ||
                    [],
                },
              ]}
              categories={data?.step2_stage1?.map(
                (item) => item?.day?.trim() || []
              )}
              colors={["#4CB944", "#FF5733"]} // Optional: pass different colors
            />
          </div>
        </div>
        <div className="papercon">
          <div className="col1_bottom_lower">
            <StageStatistics
              title="Risk Management Stats (Stage 2)"
              seriesData={[
                {
                  name: "Total Passed",
                  data:
                    (data &&
                      data?.step2_stage2?.map((item) => item.total_passed)) ||
                    [],
                },
                {
                  name: "Total Failed",
                  data:
                    (data &&
                      data?.step2_stage2?.map((item) => item.total_failed)) ||
                    [],
                },
              ]}
              categories={data?.step2_stage2?.map(
                (item) => item?.day?.trim() || []
              )}
              colors={["#4CB944", "#FF5733"]} // Optional: pass different colors
            />
          </div>
        </div>
        <div className="papercon">
          <div className="col1_bottom_lower">
            <StageStatistics
              title="Risk Management Stats (Funded)"
              seriesData={[
                {
                  name: "Total Failed",
                  data:
                    (data &&
                      data?.step2_funded?.map((item) => item.total_failed)) ||
                    [],
                },
                {
                  name: "Total In Progress",
                  data:
                    (data &&
                      data?.step2_funded?.map(
                        (item) => item.total_in_progress
                      )) ||
                    [],
                },
              ]}
              categories={data?.step2_funded?.map(
                (item) => item.start_date.trim() || []
              )}
              colors={["#FF5733", "#ffca28"]} // Optional: pass different colors
            />
          </div>
        </div>
        <div className="papercon">
          <div className="col1_bottom_lower">
            <StageStatistics
              title="Risk Management Stats (Funded PNL)"
              seriesData={[
                {
                  name: "Funded Profit",
                  data:
                    (data &&
                      data?.step2_funded_pnl?.map(
                        (item) => item.funded_profit
                      )) ||
                    [],
                },
                {
                  name: "Funded Loss",
                  data:
                    (data &&
                      data?.step2_funded_pnl?.map(
                        (item) => item.funded_loss
                      )) ||
                    [],
                },
              ]}
              categories={data?.step2_funded_pnl?.map(
                (item) => item.date.trim() || []
              )}
              colors={["#4CB944", "#FF5733"]} // Optional: pass different colors
            />
          </div>
        </div>
      </div>
    </>
  );
};
