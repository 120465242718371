import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import NavPanel from "../layouts/NavPanel";
import Signin from "../pages/Auth/Signin";
import AdminOverview from "../pages/AdminOverview";
import Leaderboard from "../pages/Leaderboard";
import CompetitionList from "../pages/CompetitionList";
import TradersList from "../pages/TradersList";
import MyProfile from "../pages/MyProfile";
import FundingEvaluation from "../pages/FundingEvaluation";
import Affiliate from "../pages/AffiliateMarketing";
import CreateCodes from "../components/Affiliate/CreateCodes";
import CreateCompetition from "../pages/CreateCompetition";
import Notifications from "../pages/Notifications";
import Support from "../pages/Support";
import Ads from "../pages/Ads";
import CreateAd from "../pages/CreateAd";
import Coupons from "../pages/Coupons";
import CreateCoupon from "../pages/CreateCoupon";
import SuperAdmin from "../pages/SuperAdmin";
import Funding from "../pages/Funding";
import Plans from "../pages/Plans";
import CreatePlan from "../pages/CreatePlan";
import TradersList2 from "../pages/TradersList2";
import TradersInfo from "../pages/TradersInfo";
import CreateEmail from "../pages/CreateEmail";
import PrivateRoutes from "./PrivateRoutes";
import EditCompetition from "../pages/EditCompetition";
import EditCoupon from "../pages/EditCoupon";
import EditAdv from "../pages/EditAdv";
import Overview from "../pages/overview";
import EditPlan from "../pages/EditPlan";
import PaymentList from "../pages/PaymentList";
import TraderListSuper from "../pages/TradersListSuper";
import UserList from "./../pages/UserList/index";
import IPlogs from "../pages/IPlogs";
import CertificateManagement from "../pages/CertificateManagement";
import StageManagement from "../pages/StageManagement";
import AdvancedStats from "../pages/AdvancedStats";
import PayoutList from "../pages/PayoutList";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import CreateCouponNew from "../pages/CreateCoupon copy";
import { useSelector } from "react-redux";

function Router() {
  const { adminAccess, custom_permissions } = useSelector(
    (state) => state.auth
  );
  const sidebarList = [
    {
      adminAccess: custom_permissions?.includes("risk_management"),
      path: "/risk-management",
    },
    {
      adminAccess: custom_permissions?.includes("trader_list"),
      path: "/super-trader-list/",
    },
    {
      adminAccess: custom_permissions?.includes("stage_manager"),
      path: "/stage-management",
    },
    {
      adminAccess: custom_permissions?.includes("user_management"),
      path: "/user-list",
    },
    {
      adminAccess: custom_permissions?.includes("account_creation"),
      path: "/funding-evaluation",
    },
    {
      adminAccess: custom_permissions?.includes("certificates"),
      path: "/CertificateManagement",
    },
    {
      adminAccess: custom_permissions?.includes("payments"),
      path: "/paymentlist",
    },
    {
      adminAccess: custom_permissions?.includes("payout"),
      path: "/payout_list",
    },
    { adminAccess: custom_permissions?.includes("coupons"), path: "/coupons" },
    {
      adminAccess: custom_permissions?.includes("affiliate_market"),
      path: "/affiliate-marketing",
    },
    {
      adminAccess: custom_permissions?.includes("admin_support"),
      path: "/superadmin",
    },
    { adminAccess: custom_permissions?.includes("ip_log"), path: "/ip-log" },
  ];

  const firstAdminPath = sidebarList.find((item) => item.adminAccess)?.path;

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/"
            exact={true}
            element={<Navigate to={firstAdminPath || "signin"} />}
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/overview/*"
            element={
              <NavPanel headerName="Overview">
                <Overview />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/payout_list/*"
            element={
              <NavPanel headerName="Payout">
                <AdvancedStats />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/risk-management/*"
            element={
              <NavPanel headerName="Risk Management">
                <AdminOverview />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/AL"
            element={
              <NavPanel headerName="Admin Login">
                <AdminLogin />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/super-trader-list"
            element={
              <NavPanel headerName="Trader List">
                <TraderListSuper />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/traders-list"
            element={
              <NavPanel headerName="Traders List">
                <TradersList />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/traders-list-2/*"
            element={
              <NavPanel headerName="Traders List">
                <TradersList2 />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/ip-log"
            element={
              <NavPanel headerName="IP Log">
                <IPlogs />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/leaderboard/:id?"
            element={
              <NavPanel headerName="Leaderboard">
                <Leaderboard />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/competition-list"
            element={
              <NavPanel headerName="Competition List">
                <CompetitionList />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/create-competition/:id?"
            element={
              <NavPanel headerName="Create Competition">
                <CreateCompetition />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/edit-competition/:id"
            element={
              <NavPanel headerName="Edit Competition">
                <EditCompetition />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/ads"
            element={
              <NavPanel headerName="All Ads">
                <Ads />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/create-ad"
            element={
              <NavPanel headerName="Create Ad">
                <CreateAd />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/edit-adv/:id"
            element={
              <NavPanel headerName="Edit Advertisement">
                <EditAdv />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/paymentlist"
            element={
              <NavPanel headerName="Payment List">
                <PaymentList />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/user-list"
            element={
              <NavPanel headerName="User List">
                <UserList />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/stage-management/*"
            element={
              <NavPanel headerName="Stage Manager">
                <StageManagement />
              </NavPanel>
            }
          />
        </Route>
        {/* <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/payout_list/*"
            element={
              <NavPanel headerName="Payout List">
                <PayoutList />
              </NavPanel>
            }
          />
        </Route> */}
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/coupons"
            element={
              <NavPanel headerName="Coupons">
                <Coupons />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/coupons/create-coupon/*"
            element={
              <NavPanel headerName="Coupons">
                {/* <CreateCoupon /> */}
                <CreateCouponNew />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/edit-coupon/:id"
            element={
              <NavPanel headerName="Edit Coupon">
                <EditCoupon />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/plans"
            element={
              <NavPanel headerName="Plans">
                <Plans />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/create-plan"
            element={
              <NavPanel headerName="Create Plan">
                <CreatePlan />
              </NavPanel>
            }
          />
          <Route element={<PrivateRoutes />}>
            <Route
              exact={true}
              path="/edit-plan/:id"
              element={
                <NavPanel headerName="Edit Plans">
                  <EditPlan />
                </NavPanel>
              }
            />
          </Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/myprofile/*"
            element={
              <NavPanel headerName="My Profile">
                <MyProfile />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/funding-evaluation"
            element={
              <NavPanel headerName="Funding Evaluation">
                <FundingEvaluation />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/CertificateManagement/*"
            element={
              <NavPanel headerName="Certificate Management">
                <CertificateManagement />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/affiliate-marketing/*"
            element={
              <NavPanel headerName="Affiliate Marketing">
                <Affiliate />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          {/* <Route
            exact={true}
            path="/affiliate-marketing/create-codes"
            element={
              <NavPanel headerName="Create Affiliate Codes">
                <CreateCodes />
              </NavPanel>
            }
          /> */}
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/notifications"
            element={
              <NavPanel headerName="Notifications">
                <Notifications />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/support"
            element={
              <NavPanel headerName="Support">
                <Support />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/superadmin/*"
            element={
              <NavPanel headerName="Super Admin Support">
                <SuperAdmin />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            path="/traders-info/*"
            element={
              <NavPanel headerName="Traders-info">
                <TradersInfo />
              </NavPanel>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/create-email"
            element={
              <NavPanel headerName="Create Email/Notification">
                <CreateEmail />
              </NavPanel>
            }
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            exact={true}
            path="/funding"
            element={
              <NavPanel headerName="Funding Page">
                <Funding />
              </NavPanel>
            }
          />
        </Route>

        <Route path="/signin" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
