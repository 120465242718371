import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SearchNew } from "../../assets/icons/search-svgrepo-com (1).svg";

import { ipLogsReq } from "../../utils/api/apis";
import { returnErrors } from "../../store/reducers/error";
import { setIsLoading } from "../../store/reducers/authSlice";
import moment from "moment";
import { Pagination } from "@mui/material";
import { statusClassMap } from "../../utils/string";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
const IPlogsTable = ({ userEmail }) => {
  const [blocked, setBlocked] = useState(null);
  const [search, setSearch] = useState(null);
  const dropdownRef = useRef(null);
  //   const AuthType = [
  //     { label: "All", value: null },
  //     { label: "email", value: "email" },
  //     { label: "Google", value: "google" },
  //   ];
  //   const statusOptions = [
  //     { label: "All", value: null },
  //     { label: "Open", value: "open" },
  //     { label: "Succeeded", value: "succeeded" },
  //   ];
  const [authT, setAuthT] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const inputRef = useRef(null);
  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };

  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] =
    useState(null);
  return (
    <div className="table_scroll_wrapper iplogs">
      <div className="alltable">
        <div className="alltable_header">
          {!userEmail && (
            <form
              className="search_container"
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(searchValue);
                setCurrentPage(1);
              }}
            >
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
              />
              <button type="submit">
                <div style={{ width: "20px", padding: "3px 10px 0 0" }}>
                  <SearchNew style={{ width: "100%" }} />
                </div>
              </button>
            </form>
          )}
          <div className="sort_buttons">
            {/* <Select
                placeholder="Auth Type"
                classNamePrefix="react-select"
                options={AuthType}
                value={authT}
                onChange={(selectedOption) => {
                 
                  selectedOption.value === null
                    ? setAuthT(null)
                    : setAuthT(selectedOption);
                }}
              /> */}
            <div style={{ flex: "1", display: "flex", gap: "10px" }}>
              <button
                onClick={() => {
                  blocked === "False" ? setBlocked(null) : setBlocked("False");
                }}
                className={blocked === "False" ? "activeBtn" : ""}
              >
                Allowed
              </button>

              <button
                onClick={() => {
                  blocked === "True" ? setBlocked(null) : setBlocked("True");
                }}
                className={blocked === "True" ? "activeBtn" : ""}
              >
                Blocked
              </button>
            </div>
          </div>
        </div>
        <div className="table_wrapper">
          <div className="table_scroll_wrapper">
            <Table
              search={userEmail || search}
              blocked={blocked}
              setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
              setDeleteConfirmationModalContent={
                setDeleteConfirmationModalContent
              }
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPlogsTable;

const Table = ({ search, blocked, currentPage, setCurrentPage }) => {
  const [filter, setFilter] = useState(null);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);

  const fetch = async () => {
    dispatch(setIsLoading(true));
    const response = await ipLogsReq(idToken, search, blocked, currentPage);

    if (response?.status < 399) {
      setTableData(response?.data?.results);
      const test = Math.ceil(response?.data?.count / 20);
      setTotalPages(Math.ceil(response?.data?.count / 20));
    } else {
      dispatch(returnErrors("Error getting IP logs list"));
    }
    dispatch(setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, [idToken, filter, search, blocked, currentPage]);

  // const data = tableData;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [code, setCode] = useState();

  const handleDelete = (couponCode) => {
    setCode(couponCode);
  };

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData =
    filter === "All"
      ? tableData
      : filter === true
      ? tableData.filter((item) => item.is_active === true)
      : tableData.filter((item) => item.is_active === false);

  const handleCopyToClipboard = (text) => {
    toast("Copied email", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "UID",
      //   accessor: "uid",
      //   Cell: ({ value }) => (
      //     <td
      //       style={{ maxWidth: "240px", overflow: "hidden" }}
      //       className="code"
      //     >
      //       {value}
      //     </td>
      //   ),
      // },
      {
        Header: "User",
        accessor: "user",
        Cell: ({ value }) => {
          const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
            16
          )}`;

          return (
            <>
              <div className="column_one_wrapper">
                <div
                  className="sno_wrapper"
                  style={{ backgroundColor: randomColor }}
                >
                  {value.charAt(0).toUpperCase()}
                </div>
                <div>{value}</div>
                <CopyToClipboard onCopy={handleCopyToClipboard} text={value}>
                  {/* <button> */}
                  <p
                    className="table_copy_button"
                    style={{
                      marginRight: "20px",
                      // backgroundColor: "#35d269",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                  >
                    {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#FFF" />
                      <path
                        d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                        stroke="#36D66B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                  {/* </button> */}
                </CopyToClipboard>
              </div>
            </>
          );
        },
      },

      {
        Header: "Date Created",
        accessor: "created",
        Cell: ({ value }) => (
          <td style={{ paddingRight: "20px" }}>{moment(value).format("ll")}</td>
        ),
      },
      { Header: "IP", accessor: "ip" },
      { Header: "Region", accessor: "region" },
      //   {
      //     Header: "Country",
      //     accessor: "country",
      //     Cell: ({ row, value }) => {
      //       // <td style={{ maxWidth: "80px", overflow: "hidden" }}>
      //       //   {value === null || value === "undefined" ? "N/A" : value}
      //       // </td>
      //       const selectedCountry = country
      //         .filter(
      //           (c) =>
      //             c.code === row?.original?.country_code || c?.country === value
      //         )
      //         .map((item) => item)[0];

      //       return (
      //         <div style={{ cursor: "pointer" }}>
      //           {" "}
      //           <HtmlTooltip
      //             title={
      //               (value === "undefined" && selectedCountry?.country) || value
      //             }
      //             placement="right"
      //             followCursor={true}
      //             slotProps={{
      //               popper: {
      //                 modifiers: [
      //                   {
      //                     name: "offset",
      //                     options: {
      //                       offset: [0, 20],
      //                     },
      //                   },
      //                 ],
      //               },
      //             }}
      //             arrow
      //           >
      //             <div>
      //               <ReactCountryFlag
      //                 countryCode={selectedCountry?.iso}
      //                 svg
      //                 style={{
      //                   width: "2em",
      //                   height: "2em",
      //                 }}
      //                 title={value}
      //                 aria-label="United States"
      //               />
      //             </div>
      //           </HtmlTooltip>{" "}
      //         </div>
      //       );
      //     },
      //   },

      // mahendra code

      // {
      //   Header: "Active",
      //   accessor: "active",
      //   Cell: ({ value }) => <td>{value ? "Active" : "blocked"}</td>,
      // },
      {
        Header: "Status",
        accessor: "blocked",
        Cell: ({ value }) => (
          <td>
            {
              <div
                className={`status_wrapper ${
                  !value ? statusClassMap.succeeded : statusClassMap.expired
                }`}
              >
                {!value ? "Allowed" : "Blocked"}
              </div>
            }
          </td>
        ),
      },
      //   {
      //     Header: "Action",
      //     accessor: "",
      //     Cell: ({ row }) => {
      //
      //       const [anchorEl, setAnchorEl] = React.useState(null);
      //       const [confirmationModal, setConfirmationModal] =
      //         React.useState(false);

      //       const open = Boolean(anchorEl);
      //       const handleClick = (event) => {
      //         setAnchorEl(event.currentTarget);
      //       };
      //       const handleClose = () => {
      //         setAnchorEl(null);
      //       };
      //       const dispatch = useDispatch();

      //       const [data, setData] = useState();
      //       const [email, setEmail] = useState();
      //       const handleStatus = async (e) => {
      //         setConfirmationModal(true);
      //         setData({ status: e.active ? "disable" : "enable" });
      //         setEmail(e.email);
      //       };
      //       const handleDelete = async (e) => {
      //         const response = await changeUserStatus(idToken, email, data);
      //         if (response?.status < 399) {
      //           dispatch(
      //             returnMessages(response?.data?.detail, response?.status)
      //           );
      //         } else {
      //           let msg =
      //             response?.response?.data?.detail || "Something went wrong";
      //           dispatch(returnErrors(msg, 400));
      //         }
      //       };
      //       const handleResetPassword = async (e) => {
      //         const data = { email: e };
      //         const response = await resetPassword(idToken, data);
      //         if (response?.status < 399) {
      //           dispatch(
      //             returnMessages(response?.data?.detail, response?.status)
      //           );
      //         } else {
      //           let msg =
      //             response?.response?.data?.detail || "Something went wrong";
      //           dispatch(returnErrors(msg, 400));
      //         }
      //       };
      //       return (
      //         <div>
      //           {confirmationModal && (
      //             <ConfirmationModal
      //               // id={selectedCompId}
      //               setConfirmationModal={setConfirmationModal}
      //               onConfirm={handleDelete}
      //             />
      //           )}
      //           <Box
      //             sx={{
      //               display: "flex",
      //               alignItems: "center",
      //               textAlign: "center",
      //             }}
      //           >
      //             <Tooltip title="Account settings">
      //               <IconButton
      //                 onClick={handleClick}
      //                 size="small"
      //                 sx={{ ml: 2 }}
      //                 aria-controls={open ? "account-menu" : undefined}
      //                 aria-haspopup="true"
      //                 aria-expanded={open ? "true" : undefined}
      //               >
      //                 <MoreVertIcon />
      //               </IconButton>
      //             </Tooltip>
      //           </Box>
      //           <Menu
      //             anchorEl={anchorEl}
      //             id="account-menu"
      //             open={open}
      //             onClose={handleClose}
      //             onClick={handleClose}
      //             PaperProps={{
      //               elevation: 0,
      //               sx: {
      //                 minWidth: "150px",
      //                 overflow: "visible",
      //                 filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      //                 mt: 1.5,
      //                 "& .MuiAvatar-root": {
      //                   width: 32,
      //                   height: 32,
      //                   ml: -0.5,
      //                   mr: 1,
      //                 },
      //                 "&::before": {
      //                   content: '""',
      //                   display: "block",
      //                   position: "absolute",
      //                   top: 0,
      //                   right: 14,
      //                   width: 10,
      //                   height: 10,
      //                   bgcolor: "background.paper",
      //                   transform: "translateY(-50%) rotate(45deg)",
      //                   zIndex: 0,
      //                 },
      //               },
      //             }}
      //             transformOrigin={{ horizontal: "right", vertical: "top" }}
      //             anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      //           >
      //             <MenuItem onClick={() => handleStatus(row.original)}>
      //               {row.original.active ? "Block" : "Activate"}
      //             </MenuItem>
      //             {row?.original?.auth_type === "email" ? (
      //               <MenuItem
      //                 onClick={() => handleResetPassword(row.original.email)}
      //               >
      //                 Reset Password
      //               </MenuItem>
      //             ) : (
      //               ""
      //             )}
      //           </Menu>
      //         </div>
      //       );
      //     },
      //   },
    ],
    []
  );
  const [inputText, setInputText] = useState("");

  // const filteredDataCoupon = tableData.filter((el) => {
  //
  //   return el.code.toLowerCase().includes(inputText);
  // });
  //

  useEffect(() => {
    setPageNumbers([...Array(totalPages).keys()].map((i) => i + 1));
  }, [totalPages]);

  const handlePagination = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };
  return (
    <div className="table_wrapper">
      <div style={{ marginTop: "20px" }} className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`${
                        cell.column.Header === "Status" ? "status_column" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>{" "}
      </div>
      {/* <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button
          style={{ color: `${currentPage === 1 ? "#a8a8a8" : "black"}` }}
          onClick={() => {
            if (currentPage === 1) {
              return;
            } else {
              handlePagination(currentPage - 1);
            }
          }}
        >
          Previous
        </button>
        <span style={{ display: "flex", gap: "15px", fontWeight: "100" }}>
          {pageNumbers.map((pageNumber, idx) => (
            <strong key={pageNumber}>
              {currentPage === pageNumber ? (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "900",
                  }}
                >
                  {pageNumber}
                </span>
              ) : (
                <div
                  onClick={() => {
                    handlePagination(pageNumber);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "20",
                    padding: "4px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    cursor: "pointer",
                  }}
                >
                  {pageNumber}
                </div>
              )}
            </strong>
          ))}
        </span>
        <button
          style={{
            color: `${currentPage + 1 > totalPages ? "#a8a8a8" : "black"}`,
          }}
          onClick={() => {
            if (currentPage + 1 > totalPages) {
              return;
            } else {
              handlePagination(currentPage + 1);
            }
          }}
        >
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div> */}
      <div className="spacerT30p"></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 0px",
        }}
      >
        <Pagination
          onChange={handleChange}
          count={totalPages}
          variant="outlined"
          page={currentPage}
          color="primary"
        />
        <div className="goto-page-input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
      </div>
    </div>
  );
};
