import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon } from "../../store/reducers/couponSlice";
import { getPaymentList } from "../../utils/api/apis";
import { ReactComponent as SearchNew } from "../../assets/icons/search-svgrepo-com (1).svg";

import { returnErrors } from "../../store/reducers/error";
import { setIsLoading } from "../../store/reducers/authSlice";
import moment from "moment";
import Select from "react-select";
import { statusClassMap } from "../../utils/string";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatCurrency } from "../../utils/helpers/string";

function PaymentListTable() {
  const handleNavigateToOverView = async (value) => {
    // dispatch(setIsLoading(true));
  };

  const [searchedUser, setSearchUser] = useState([]);
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [search, setSearch] = useState(null);
  const dropdownRef = useRef(null);
  const planOptions = [
    { label: "All", value: null },
    { label: "1 Step", value: "1 Step" },
    { label: "2 Step", value: "2 Step" },
  ];
  const statusOptions = [
    { label: "All", value: null },
    { label: "Open", value: "open" },
    { label: "Succeeded", value: "succeeded" },
  ];
  const [plan, setPlan] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] =
    useState(null);
  const [platform, setPlatform] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <div className="payments_table">
      <div className="table_scroll_wrapper">
        <div className="alltable">
          <div className="alltable_header">
            <form
              style={{ marginTop: "20px" }}
              className="search_container"
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(searchValue);
                // setSearchValue()
                setCurrentPage(1);
              }}
            >
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
              />
              <button type="submit">
                <div
                  style={{ padding: "3px 10px 0 0", width: "20px !important" }}
                >
                  <SearchNew width={"20px"} />
                </div>
              </button>
            </form>
            <div className="sort_buttons">
              <div className="mobile_dropDowns">
                <Select
                  placeholder="Plan Type"
                  classNamePrefix="react-select"
                  options={planOptions}
                  value={plan}
                  onChange={(selectedOption) =>
                    selectedOption.value === null
                      ? setPlan(null)
                      : setPlan(selectedOption)
                  }
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "6px",
                  width: "100%",
                }}
                className="desktop_pills"
              >
                <p>Plans</p>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  {planOptions.map((tab, index) => (
                    <div
                      key={index}
                      className={
                        plan && plan.label === tab.label
                          ? "active"
                          : "normalbtn"
                      }
                      onClick={() => setPlan(tab)}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
              </div>

              <div className="line"></div>

              <div className="mobile_dropDowns">
                <Select
                  placeholder="Status"
                  classNamePrefix="react-select"
                  options={statusOptions}
                  value={status}
                  onChange={(selectedOption) =>
                    selectedOption.value === null
                      ? setStatus(null)
                      : setStatus(selectedOption)
                  }
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "6px",
                  width: "100%",
                }}
                className="desktop_pills"
              >
                <p>Status</p>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  {statusOptions.map((tab, index) => (
                    <div
                      key={index}
                      className={
                        status && status.label === tab.label
                          ? "active"
                          : "normalbtn"
                      }
                      onClick={() => setStatus(tab)}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "6px",
                  width: "100%",
                }}
                className="desktop_pills"
              >
                <p>Platform</p>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    className={
                      platform === "matchtrade" ? "active" : "normalbtn"
                    }
                    onClick={() => {
                      setCurrentPage(1);
                      setPlatform("matchtrade");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Matchtrade
                  </div>
                  <div
                    className={
                      platform === "tradelocker" ? "active" : "normalbtn"
                    }
                    onClick={() => {
                      setCurrentPage(1);
                      setPlatform("tradelocker");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Tradelocker
                  </div>
                </div>
              </div>
            </div>
          </div>
          {searchDropDown && (
            <div className="challenge_dropdown" ref={dropdownRef}>
              <ul>
                {searchedUser.map((user) => (
                  <li
                    key={user.email}
                    style={{
                      position: "relative",
                      zIndex: "100",
                      backgroundColor: "#111142",
                    }}
                    onClick={() => {
                      handleNavigateToOverView(user.email);
                    }}
                  >
                    {user.email}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="table_wrapper">
            <div className="table_scroll_wrapper">
              <Table
                search={search}
                searchValue={searchValue}
                plan={plan?.value}
                currentPage={currentPage}
                status={status?.value}
                platform={platform}
                setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
                setDeleteConfirmationModalContent={
                  setDeleteConfirmationModalContent
                }
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>

        {isDeleteConfirmationModalOpen && (
          <DeleteModal
            isModalOpen={isDeleteConfirmationModalOpen}
            closeModal={() => setDeleteConfirmationModalOpen(false)}
            deleteContent={deleteConfirmationModalContent}
          />
        )}
      </div>
    </div>
  );
}
const handleCopyToClipboard = (text) => {
  toast("Copied email", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "success",
    theme: "dark",
    className: "custom-toast-container",
  });
};
export default PaymentListTable;

const Table = ({
  search,
  status,
  searchValue,
  plan,
  platform,
  currentPage,
  setCurrentPage,
}) => {
  const [filter, setFilter] = useState(null);
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  // const coupon = useSelector((state) => state.coupon.coupon);
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  console.log(search, searchValue, "status,searchValuestatus,searchValue");
  const fetch = async () => {
    dispatch(setIsLoading(true));
    const response = await getPaymentList(
      idToken,
      search,
      status,
      plan,
      currentPage ? currentPage : 1,
      searchValue,
      platform
    );
    if (response?.status < 399) {
      setTableData(response.data.results);

      setTotalPages(Math.ceil(response?.data?.count / 21));
    } else {
      dispatch(returnErrors("Error getting payment list"));
    }
    dispatch(setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, [idToken, filter, search, status, plan, currentPage, platform]);

  // const data = tableData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Pagination Logic

  // setting total pages
  useEffect(() => {
    setPageNumbers([...Array(totalPages).keys()].map((i) => i + 1));
  }, [totalPages]);

  const handlePagination = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [code, setCode] = useState();
  const handleDelete = (couponCode) => {
    setCode(couponCode);
  };

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData =
    filter === "All"
      ? tableData
      : filter === true
      ? tableData.filter((item) => item.is_active === true)
      : tableData.filter((item) => item.is_active === false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => {
          const [randomColor, setRandomColor] = useState();
          const getRandomColor = `#${Math.floor(
            Math.random() * 16777215
          ).toString(16)}`;
          if (!randomColor) {
            setRandomColor(getRandomColor);
          }

          return (
            <>
              <div className="column_one_wrapper">
                <div
                  className="sno_wrapper"
                  style={{ backgroundColor: randomColor }}
                >
                  {value.charAt(0).toUpperCase()}
                </div>
                <div>{value}</div>
                <CopyToClipboard onCopy={handleCopyToClipboard} text={value}>
                  {/* <button> */}
                  <p
                    className="table_copy_button"
                    style={{
                      marginRight: "20px",
                      // backgroundColor: "#35d269",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                  >
                    {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#FFF" />
                      <path
                        d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                        stroke="#36D66B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                  {/* </button> */}
                </CopyToClipboard>
              </div>
            </>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }) => <td className="code">{value}</td>,
      },
      { Header: "Plan", accessor: "funding_evaluation" },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <td>{formatCurrency(Number(value) || 0)}</td>,
      },
      {
        Header: "Status",
        accessor: "payment_status",
        Cell: ({ value, row }) => (
          <span className={`status_wrapper ${statusClassMap[value]}`}>
            {value.toUpperCase()}
          </span>
        ),
      },
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }) => (
          <td>{value ? moment(value).format("ll") : "NA"}</td>
        ),
      },
    ],
    []
  );
  const [inputText, setInputText] = useState("");

  // const filteredDataCoupon = tableData.filter((el) => {
  //
  //   return el.code.toLowerCase().includes(inputText);
  // });
  //

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });
  const handleChange = (e, value) => {
    setCurrentPage(value);
  };

  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };
  return (
    <div className="table_wrapper">
      <div style={{ marginTop: "20px" }} className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`${
                        cell.column.Header === "Status" ? "status_column" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>{" "}
      </div>
      {/* <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button
          style={{ color: `${currentPage === 1 ? "#a8a8a8" : "black"}` }}
          onClick={() => {
            if (currentPage === 1) {
              return;
            } else {
              handlePagination(currentPage - 1);
            }
          }}
        >
          Previous
        </button>
        <span style={{ display: "flex", gap: "15px", fontWeight: "100" }}>
          {pageNumbers.map((pageNumber, idx) => (
            <strong key={pageNumber}>
              {currentPage === pageNumber ? (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "900",
                  }}
                >
                  {pageNumber}
                </span>
              ) : (
                <div
                  onClick={() => {
                    handlePagination(pageNumber);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "20",
                    padding: "4px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    cursor: "pointer",
                  }}
                >
                  {pageNumber}
                </div>
              )}
            </strong>
          ))}
        </span>
        <button
          style={{
            color: `${currentPage + 1 > totalPages ? "#a8a8a8" : "black"}`,
          }}
          onClick={() => {
            if (currentPage + 1 > totalPages) {
              return;
            } else {
              handlePagination(currentPage + 1);
            }
          }}
        >
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div> */}
      <div className="spacerT30p"></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 0px",
        }}
      >
        <Pagination
          onChange={handleChange}
          count={totalPages}
          variant="outlined"
          page={currentPage}
          color="primary"
        />
        <div className="goto-page-input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteModal = ({
  isModalOpen,
  closeModal,
  deleteContent,
  onConfirmDelete,
  idToken,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteCoupon({ idToken: idToken, code: deleteContent }));
    closeModal();
  };

  return (
    <div className="modal">
      <div className="modal_content">
        <p>Are you sure you want to delete?</p>
        <div className="modal_btn">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={closeModal}>No</button>
        </div>
      </div>
    </div>
  );
};
