import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Profile from "../../../../assets/icons/profile.svg";
import { returnErrors } from "../../../../store/reducers/error";
import { returnMessages } from "../../../../store/reducers/message";
import {
  baseUrl,
  changeUserPassword,
  getUserDetailsnew,
  getUserProfileData,
  updateUserDetailsNew,
  updateUserDetailsnew,
  // getUserDetailsnew,
  // updateUserDetailsnew,
} from "../../../../utils/api/apis";
import { country } from "../../../../utils/constants/country.js";
import EditIcon from "../../../CompetitionList/CompetitionDetailsBox/icons/editIcon";
import "./style.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { setSelectedUser } from "../../../../store/reducers/userSlice.js";
import customStyles from "../../../../customstyle/customstyle.jsx";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function AccountDetails({ userDetails }) {
  const options = country;
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);

  const { selectedUser } = useSelector((state) => state.user);
  const [userDetail, setUserDetail] = useState();

  const [firstRender, setFirstRender] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (firstRender) {
      dispatch(setSelectedUser(userDetails));
      setFirstRender(false);
    }
  }, [userDetails, dispatch, firstRender]);

  // Profile get

  const fetchUserDetails = async () => {
    const res = await getUserProfileData(idToken, userDetails?.email);
    setUserDetail(res?.data);
  };

  useEffect(() => {
    fetchUserDetails();
  }, [idToken]);

  // Profile update

  const handleUpdateProfile = async () => {
    const response = await updateUserDetailsNew(idToken, userDetail);
    //removed clg
    if (response.status === 200) {
      dispatch(returnMessages("Profile updated successfully", 200));
    } else {
      dispatch(returnErrors("Profile updated failed", 400));
    }
  };

  // Password change

  const [passwordLoading, setPasswordLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("New Password is required")
        .min(8, "Password must be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      setPasswordLoading(true);

      try {
        const payload = {
          email: userDetails.email,
          password: values.newPassword,
        };
        const response = await changeUserPassword(idToken, payload);
        if (response.status < 400) {
          dispatch(returnMessages("Password Reset Successfully", 200));
          values = {
            confirmPassword: "",
            newPassword: "",
          };
        } else {
          dispatch(
            returnErrors(
              response?.response?.data?.details || "Failed to update password",
              400
            )
          );
        }
        handleClose();
        setPasswordLoading(false);
      } catch (error) {
        dispatch(returnErrors("Something went wrong", 400));
        setPasswordLoading(false);
      }
      setPasswordLoading(false);
    },
  });

  return (
    <div className="account_details">
      <div className="account_details_upper_wrapper">
        <div className="account_details_upper">
          <div className="account_img_wrapper">
            <img src={Profile} alt="profile icon" />
          </div>
          <div className="account_details_upper_content">
            <h4>{userDetails?.email}</h4>
            {/* <p>Funded Trader</p> */}
          </div>
          <div className="account_edit_img_wrapper">
            {" "}
            {/* <img src={Edit} alt="edit icon" /> */}
          </div>
        </div>
        {/* <img src={MenuDots} alt="" /> */}
      </div>
      <hr></hr>

      <div className="account_details_lower">
        <div className="account_form">
          <form>
            <div className="account_input_container_upper">
              {/* <div className="account_input">
                <label htmlFor="nickname" className="label_black">
                  Nick Name
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.nickname}
                  name="nickname"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      nickname: e.target.value,
                    }))
                  }
                />
              </div> */}
              <div className="account_input">
                <label htmlFor="firstName" className="label_black">
                  First Name
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.first_name}
                  name="firstName"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      first_name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="account_input">
                <label htmlFor="lastName">Last Name</label>

                <input
                  className="account_details_input"
                  type="text"
                  name="lastName"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      last_name: e.target.value,
                    }))
                  }
                  value={userDetail?.last_name}
                />
              </div>
              <div className="account_input">
                <label htmlFor="contact">Contact Number</label>

                <input
                  className="account_details_input"
                  type="text"
                  //   value="+91  9797987891"
                  name="contact"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      contact: e.target.value,
                    }))
                  }
                  value={userDetail?.contact}
                />
              </div>{" "}
              <div className="account_input">
                <label htmlFor="city" className="label_black">
                  City
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.city}
                  name="city"
                  onChange={(e) =>
                    setUserDetail((pre) => ({ ...pre, city: e.target.value }))
                  }
                />
              </div>
              <div className="account_input">
                <label htmlFor="country_code" className="label_black">
                  Country code
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.country_code}
                  name="country_code"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      country_code: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="account_input">
                <label htmlFor="email" className="label_black">
                  Email
                </label>

                <input
                  disabled
                  className="account_details_input"
                  type="text"
                  value={userDetail?.email}
                  name="email"
                  onChange={(e) =>
                    setUserDetail((pre) => ({ ...pre, name: e.target.value }))
                  }
                />
              </div>
              <div className="account_input">
                <label htmlFor="country">Country</label>

                <Select
                  options={options?.map((item) => ({
                    label: item.country,
                    value: item.country,
                    code: item.code,
                  }))}
                  styles={customStyles}
                  value={
                    {
                      value: userDetail?.country,
                      label: userDetail?.country,
                      code: userDetail?.code,
                    } || options[0]
                  }
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  name="country"
                  id="country"
                  onChange={(selectedOption) =>
                    setUserDetail((prevUserDetail) => ({
                      ...prevUserDetail,
                      country: selectedOption.value,
                      country_code: `+${selectedOption.code}`,
                    }))
                  }
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              {/* <div className="account_input">
                <label htmlFor="trader_badge" className="label_black">
                  Trader badge
                </label>
               
                <input
                  className="account_details_input"
                  type="text"
                  placeholder="trader_badge"
                  value={userDetail?.trader_badge}
                  name="Trader badge"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      trader_badge: e.target.value,
                    }))
                  }
                />
              </div> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    ...style,
                    width: 370,
                    mx: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <form
                    onSubmit={formik.handleSubmit}
                    className="changePassword_form"
                  >
                    <div className="changePwdInput">
                      <TextField
                        label="New Password"
                        type={showNewPassword ? "text" : "password"}
                        fullWidth
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.newPassword &&
                          Boolean(formik.errors.newPassword)
                        }
                        helperText={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={toggleNewPasswordVisibility}
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Confirm New Password"
                        type={showConfirmPassword ? "text" : "password"}
                        fullWidth
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirmPassword &&
                          Boolean(formik.errors.confirmPassword)
                        }
                        helperText={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={toggleConfirmPasswordVisibility}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <Button
                      variant="contained"
                      style={{ background: "#1877f2" }}
                      type="submit"
                    >
                      {passwordLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        " Update Password"
                      )}
                    </Button>
                  </form>
                </Box>
              </Modal>
            </div>

            <div className="account_input_container_lower">
              <div
                style={{ display: "flex", gap: "10px", cursor: "pointer" }}
                onClick={handleOpen}
              >
                <label
                  style={{ margin: "0", cursor: "pointer" }}
                  className="label_black"
                >
                  Change Password
                </label>
                <EditIcon />
              </div>
              <Button
                variant="contained"
                style={{ background: "#1877f2", textTransform: "capitalize" }}
                onClick={handleUpdateProfile}
              >
                Update Profile
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
