import React, { useEffect, useState } from "react";
import "./style.scss";
import Card from "../../../reusableComponents/Card";
import { useTable, usePagination } from "react-table";

import CalenderIcon from "../../../assets/icons/calender.svg";
import DropdownIcon from "../../../assets/icons/dropdown-arrow-gray.svg";
import Profile from "../../../assets/icons/profile.svg";
import Download from "../../../assets/icons/download.svg";

import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHistoryRequest } from "../../../utils/api/apis";
import moment from "moment";
import { setIsLoading } from "../../../store/reducers/authSlice";
import { formatCurrency } from "../../../utils/helpers/string";

function TransactionHistory({ userDetails }) {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const idToken = useSelector((state) => state.auth.idToken);
  // const email = useSelector(
  //   (state) => state.account.accountList?.activeUser.email
  // );
  const userEmail = userDetails;

  const email = userEmail?.email;

  const fetch = async () => {
    dispatch(setIsLoading(true));
    const response = await getPaymentHistoryRequest({ idToken, email });
    response.status < 399 && setTableData(response.data);
    dispatch(setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, []);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      marginTop: "4px",
      height: 48,
      borderRadius: 8,
      border: "none",
      boxShadow: "none",
      paddingRight: "20px",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 700,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
  };

  return (
    <Card className="transactionhistory">
      <div className="transactionhistory_header">
        <h3>Transaction History</h3>

        <div className="select_wrapper">
          {/* <img className="select_icon" src={CalenderIcon} alt="calender icon" /> */}
        </div>
      </div>
      <div className="table_wrapper">
        <Table tableData={tableData} />
      </div>
    </Card>
  );
}

export default TransactionHistory;

const Table = ({ tableData }) => {
  const data = tableData;

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <>
            <div className="column_header">
              Funding Plan <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "funding_evaluation",
        Cell: ({ value }) => <div className="column_data">{value}</div>,
      },
      {
        Header: () => (
          <>
            <div className="column_header">
              Account Balance <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "account_balance",
        Cell: ({ row }) => {
          return formatCurrency(Number(row.original.account_balance));
        },
      },
      {
        Header: () => (
          <>
            <div className="column_header">
              Transaction ID <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "id",
      },
      {
        Header: () => (
          <>
            <div className="column_header">
              Create at <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("ll"),
      },
      {
        Header: () => (
          <>
            <div className="column_header">
              Amount <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "amount",
        Cell: ({ value }) => `$${value}`,
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => (
          <div className="column_data">
            {value ? (
              <a href={value} target="_blank">
                <img src={Download} alt="Download icon" />
              </a>
            ) : (
              "No link"
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <>
            <div className="column_header">
              Status <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "payment_status",
        Cell: ({ value }) => (
          <div className="column_data">
            <span
              className={`${
                value === "open"
                  ? "status-pending"
                  : value === "expired"
                  ? "status-error"
                  : value === "filtered"
                  ? "status-filtered"
                  : value === "declined"
                  ? "status-declined"
                  : value === "unpaid"
                  ? "status-declined"
                  : "status-successful"
              }`}
            >
              <p style={{ textTransform: "capitalize" }}>{value}</p>
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    pageOptions,
    state,
    pageIndex,
    rows,
    prepareRow,
  } = useTable({ columns, data }, usePagination);

  const [currentPage, setCurrentPage] = useState(1);
  const pageButtons = [];

  for (let i = 1; i <= pageCount; i++) {
    pageButtons.push(
      <button
        key={i}
        className={`${i === currentPage ? "button-active" : "button-inactive"}`}
        onClick={() => {
          gotoPage(i - 1);
          setCurrentPage(i);
        }}
      >
        {i < 10 ? `0${i}` : i}
      </button>
    );
  }

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button
          onClick={() => {
            previousPage();
            setCurrentPage(currentPage - 1);
          }}
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        {pageButtons}
        <button
          onClick={() => {
            nextPage();
            setCurrentPage(currentPage + 1);
          }}
          disabled={!canNextPage}
        >
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div>
    </>
  );
};
