import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import EELogo from "../../assets/icons/EEA-Logo.svg";
import Search from "../../assets/icons/search.svg";
import Bell from "../../assets/icons/bell.svg";
import Settings from "../../assets/icons/settings.svg";
import DarkTheme from "../../assets/icons/dark-theme.svg";
import Profile from "../../assets/icons/profile.svg";
import CaretDown from "../../assets/icons/caret-down.svg";
import SearchBlack from "../../assets/icons/search-black.svg";
import BlueTick from "../../assets/icons/tick-blue.svg";
import LightTheme from "../../assets/icons/light-theme.svg";
import DollarGreen from "../../assets/icons/dollar-green.svg";
import Cross from "../../assets/icons/circle-cross.svg";
import Edit from "../../assets/icons/edit-pencil.svg";
import Notification from "../../assets/icons/notification.svg";
import MarkAllRead from "../../assets/icons/tick-blue-circle.svg";
import DollarBlack from "../../assets/icons/dollar-black-square.svg";
import Standard from "../../assets/icons/standard.svg";
import FreeTrial from "../../assets/icons/free-trial.svg";
import Competition from "../../assets/icons/trophy.svg";
import InfoBox from "../../reusableComponents/InfoBox";
import MenuIcon from "../../assets/icons/menu.svg";
import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-increase.svg";
import { Link, useNavigate } from "react-router-dom";
import BellBlack from "../../assets/icons/bell-black.svg";
import Tabination from "../../reusableComponents/Tabination";
import sadFace from "../../assets/images/sadFace.svg";
import { useSearchKeywordsData } from "../../utils/constants/searchKeywords";
const Header = ({
  headerName,
  theme,
  // setTheme,
  // toggleTheme,
  mobileMenu,
  setMobileMenu,
}) => {
  const [searchBar, setSearchBar] = useState(false);
  const [bellDropdown, setBellDropdown] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const bellRef = useRef(null);
  const profileRef = useRef(null);

  const handleSearchBar = () => {
    setSearchBar(!searchBar);
  };

  const handleBellDropdown = () => {
    setProfileDropdown(false);
    setBellDropdown(!bellDropdown);
  };

  const handleProfileDropdown = () => {
    setBellDropdown(false);
    setProfileDropdown(!profileDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        bellRef.current &&
        !bellRef.current.contains(event.target) &&
        profileRef.current &&
        !profileRef.current.contains(event.target)
      ) {
        setBellDropdown(false);
        setProfileDropdown(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const searchKeywordsData = useSearchKeywordsData();

  //global search logic
  const [searchKeys, setSearchKeys] = useState(searchKeywordsData?.slice(0, 5));
  const [inputVal, setInputVal] = useState("");
  const [inputFocus, setInputFocus] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputVal(e.target.value);
    setSearchKeys(
      searchKeywordsData.filter((item) =>
        item?.content?.toLowerCase().includes(inputVal.toLowerCase())
      )
    );
  };

  const searchRef = useRef(null);
  const searchMaineRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        searchMaineRef.current &&
        !searchMaineRef.current.contains(event.target)
      ) {
        setInputFocus(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className={`header ${theme}`}>
        <div className="header_left">
          <img src={EELogo} alt="logo" />

          <h2 className={`${theme === "dark-theme" ? "dark" : "light"}`}>
            {headerName}
          </h2>
        </div>
        <div className="header_right">
          <ul className="icons_list">
            {searchBar ? (
              <div
                ref={searchRef}
                className="search_main"
                style={{
                  borderBottomLeftRadius: inputFocus ? "0" : "",
                  borderBottomRightRadius: inputFocus ? "0" : "",
                }}
              >
                <div
                  className="search-bar"
                  ref={searchMaineRef}
                  style={{ background: inputFocus ? "none" : "" }}
                >
                  <input
                    type="text"
                    className="search_input"
                    placeholder="Find something"
                    style={{
                      backgroundColor: inputFocus
                        ? "transparent !important"
                        : "",
                    }}
                    value={inputVal}
                    onChange={handleChange}
                    onFocus={(e) =>
                      e.target.focus
                        ? setInputFocus(true)
                        : setInputFocus(false)
                    }
                  />
                  <img
                    className="search_icon"
                    src={SearchBlack}
                    alt="search icon"
                    onClick={handleSearchBar}
                  />
                </div>
                <div
                  className="search_value"
                  style={{
                    display: !inputFocus ? "none" : "block",
                  }}
                >
                  <p>Suggestions</p>
                  {inputFocus &&
                    searchKeys?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            navigate(`${item.path}`);
                            setInputFocus(false);
                          }}
                          className="content_list"
                        >
                          {/* <img
                        src={item.icon}
                        alt="dashboardsvg"
                        style={{paddingLeft: "13px"}}
                      /> */}
                          <span>{item.content}</span>
                        </div>
                      );
                    })}
                  {searchKeys.length === 0 && (
                    <div className="content_list">
                      <img
                        src={sadFace}
                        alt="dashboardsvg"
                        style={{ paddingLeft: "13px", scale: "2" }}
                      />
                      <span>Not Found</span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <li onClick={handleSearchBar}>
                <img src={Search} alt="search icon" />
              </li>
            )}
            {/* <li onClick={handleBellDropdown} ref={bellRef}>
              <img src={bellDropdown ? BellBlack : Bell} alt="bell icon" />
              {bellDropdown && (
                <BellDropdown
                  bellDropdown={bellDropdown}
                  setBellDropdown={setBellDropdown}
                />
              )}
            </li> */}
            {/* <li>
              <img src={Settings} alt="settings icon" />
            </li> */}
            {/* <li>
              <img
                src={theme === "dark-theme" ? DarkTheme : LightTheme}
                alt="darktheme icon"
                // onClick={toggleTheme}
                onClick={handleTheme}
              />
            </li> */}
            {/* <div className="profile_section" ref={profileRef}>
              <li>
                <Link to="/myprofile">
                  {" "}
                  <img
                    onClick={(e) => e.stopPropagation()}
                    className={` ${profileDropdown ? "active_profile" : ""}`}
                    src={Profile}
                    alt="profile icon"
                  />{" "}
                </Link>
                {profileDropdown && (
                  <ProfileDropdown
                    profileDropdown={profileDropdown}
                    setProfileDropdown={setProfileDropdown}
                  />
                )}
              </li>
              <li onClick={handleProfileDropdown}>
                <img src={CaretDown} alt="downarrow icon" />
              </li>
            </div> */}
          </ul>
        </div>
      </header>

      <header className={`mobile_header ${theme}`}>
        <div className="header_left">
          <img src={EELogo} alt="logo" />
          <h2 className={`${theme === "dark-theme" ? "dark" : "light"}`}>
            {searchBar ? null : headerName}
          </h2>
        </div>
        <div className="header_right">
          {searchBar ? (
            <div className="search_bar_mobile">
              <input
                type="text"
                className="search_input_mobile"
                placeholder="Search..."
              />
            </div>
          ) : (
            <div onClick={handleSearchBar}>
              <img src={Search} alt="search icon" />
            </div>
          )}
          <img
            className="menu_icon"
            src={MenuIcon}
            alt="mnu icon"
            onClick={() => setMobileMenu(!mobileMenu)}
          />
        </div>
      </header>
    </>
  );
};

export default Header;

const BellDropdown = ({ bellDropdown, setBellDropdown }) => {
  const items = [
    { name: "View All", content: "" },
    { name: "By Company", content: "" },
  ];

  return (
    <div
      className="dropdown dropdown_bell"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="dropdown_header">
        <h2>Notifications</h2>
        <img
          src={Cross}
          alt="cross icon"
          onClick={() => setBellDropdown(!bellDropdown)}
        />
      </div>
      <div className="notifications_tabs">
        <Tabination variant={4} tabItems={items} />
      </div>
      <div className="notifications_wrapper">
        <div className="notification">
          <div>
            <img
              className="notification_icon"
              src={Notification}
              alt="notification"
            />
          </div>
          <div className="notification_content">
            <div className="notification_content_upper">
              <p>
                <span>Company Accepted</span> your case Account & Billing
                Support & other
              </p>
            </div>
            <div className="notification_content_bottom">
              <span className="time">Friday 2:20pm</span>
              <span className="date">31 Jan 2021</span>
            </div>
          </div>
        </div>
        <div className="notification">
          <div>
            <img src={Notification} alt="notification" />
          </div>
          <div className="notification_content">
            <div className="notification_content_upper">
              <p>
                <span>Company Accepted</span> your case Account & Billing
                Support & other
              </p>
            </div>
            <div className="notification_content_bottom">
              <span className="time">Friday 2:20pm</span>
              <span className="date">31 Jan 2021</span>
            </div>
          </div>
        </div>
      </div>

      <div className="dropdown_footer">
        <div className="dropdown_footer_bell">
          <div className="dropdown_footer_left">
            <img src={MarkAllRead} alt="tick icon" />
            <p>Mark all as read</p>
          </div>
          <div className="dropdown_footer_right">
            <button className="view_all_btn">View all notifications</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileDropdown = ({ profileDropdown, setProfileDropdown }) => {
  const infoBoxArr = [
    {
      title: "Account Balance",
      value: "$30,872",
      icon: ArrowIncrease,
      percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Equity",
      value: "$5000",
      icon: ArrowDecrease,
      percentage: "-1.2%",
      color: "#4CB944",
    },
  ];

  return (
    <div
      className={`dropdown dropdown_profile`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="dropdown_upper_wrapper">
        <div className="dropdown_header">
          <p>Account Details</p>
          <img
            src={Cross}
            alt="cross icon"
            onClick={() => setProfileDropdown(!profileDropdown)}
          />
        </div>

        <div className="dropdown_profile_content">
          <div className="profile_details">
            <div className="profile_img_wrapper">
              <img src={Profile} alt="profile icon" />
            </div>
            <div className="profile_details_content">
              <h4>Daniel Radcliff</h4>
              <p>1465376</p>
            </div>
            <div className="edit_img_wrapper">
              {" "}
              <Link to="/myprofile">
                {" "}
                <img
                  src={Edit}
                  alt="edit icon"
                  onClick={() => setProfileDropdown(false)}
                />{" "}
              </Link>
            </div>
          </div>
          <div className="info_box_container">
            {infoBoxArr.map((info) => (
              <InfoBox
                key={info.title}
                title={info.title}
                value={info.value}
                icon={info.icon}
                percentage={info.percentage}
                color={info.color}
              />
            ))}
          </div>
          <div className="accounts_type_wrapper">
            <ul className="account_type">
              <li>All Accounts</li>
              <li>
                <img src={DollarGreen} alt="aggressive account" />
                Aggressive
                <img
                  className="blue_tick_icon"
                  src={BlueTick}
                  alt="tick icon"
                />
              </li>
              <li>
                <img src={DollarBlack} alt="swing account" />
                Swing
              </li>
              <li>
                <img src={Standard} alt="standard account" />
                Standard
              </li>
              <li>
                <img src={FreeTrial} alt="free account" />
                Free Trial
              </li>
              <li>
                <img src={Competition} alt="competitions account" />
                Competitions
              </li>
            </ul>
            <ul className="live_account_type">
              <li>Live Accounts</li>
              <li>
                <img src={DollarBlack} alt="live account" />
                1234345
              </li>
              <li>
                <img src={DollarGreen} alt="live account" />
                736483
                <img
                  className="blue_tick_icon"
                  src={BlueTick}
                  alt="tick icon"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dropdown_footer">
        <div>
          <span>
            <p className="title">Start Date</p>
            <p className="value">31 Jan 2021</p>
          </span>
          <span>
            <p className="title">Expiry Date</p>
            <p className="value">31 Jan 2021 </p>
          </span>
          <span>
            <p className="title">No Of Trades</p>
            <p className="value">48</p>
          </span>
          <span>
            <p className="title">Leverage</p>
            <p className="value">1:2023</p>
          </span>
        </div>
      </div>
    </div>
  );
};
