import React, { useState, useEffect } from "react";
import "./style.scss";
import { ReactComponent as SearchNewIcon } from "../../../assets/icons/search-svgrepo-com (1).svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTradersRequest,
  sendCredential,
  updateUserAccountReq,
} from "../../../utils/api/apis";
import { setIsLoading } from "../../../store/reducers/authSlice";
import { toast, ToastContainer } from "react-toastify";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tooltip,
} from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment/moment";
import { useTable } from "react-table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { returnErrors } from "../../../store/reducers/error";
import { returnMessages } from "../../../store/reducers/message";
import ConfirmationModal from "../../../pages/FundingEvaluation/ConfirmationModal";
import { formatText, statusClassMap } from "../../../utils/string";
import { ReactComponent as CopyIcon } from "../../../assets/icons/CopyIcon.svg";
import {
  setActiveAccount,
  setActiveEmail,
  setActiveUser,
} from "../../../store/reducers/accountSlice";
// Main Component
function AllTable({ activeTab, admin }) {
  const [params, setParams] = useState({
    page: 1,
    page_size: 20,
    active: null,
    search: null,
    type: null,
    platform: null,
  });
  const [allTraders, setAllTraders] = useState([]);
  const [totalTraders, setTotalTraders] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idToken = useSelector((state) => state.auth.idToken);

  // Effect to update type based on activeTab
  useEffect(() => {
    const tabMapping = {
      0: null,
      1: "1_step",
      2: "2_step",
      3: "1_step",
      4: "2_step",
    };

    setParams((prev) => ({
      ...prev,
      type: tabMapping[activeTab],
      page: 1,
      phase: activeTab === 3 || activeTab === 4 ? "funded" : null,
    }));
  }, [activeTab]);

  // Fetch traders data
  const fetchTraders = async () => {
    dispatch(setIsLoading(true));
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== null)
    );
    try {
      const data = await getAllTradersRequest(idToken, filteredParams);
      setAllTraders(data.results);
      setTotalTraders(data.count);
    } catch (error) {
      console.error("Error fetching traders:", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchTraders();
  }, [params]);
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const searchValue = e.target.querySelector("input").value; // Get input value from the form
    setParams((prev) => ({ ...prev, search: searchValue, page: 1 }));
    fetchTraders();
  };

  return (
    <div className="alltable">
      <ToastContainer />
      <div className="alltable_header">
        <form className="search_container" onSubmit={handleSearchSubmit}>
          <input type="text" placeholder="Search..." />
          <button type="submit">
            <SearchNewIcon />
          </button>
        </form>
        <div className="sort_buttons">
          <button
            onClick={() =>
              setParams((prev) => ({
                ...prev,
                active: prev.active === "True" ? null : "True",
                page: 1,
              }))
            }
            className={params.active === "True" ? "activeBtn" : ""}
          >
            Active
          </button>
          <button
            onClick={() =>
              setParams((prev) => ({
                ...prev,
                active: prev.active === "False" ? null : "False",
                page: 1,
              }))
            }
            className={params.active === "False" ? "activeBtn" : ""}
          >
            Inactive
          </button>
          <button
            onClick={() => {
              setParams((pre) => ({ ...pre, platform: "matchtrade" }));
            }}
            className={params?.platform === "matchtrade" ? "activeBtn" : ""}
            style={{ cursor: "pointer" }}
          >
            Matchtrade
          </button>{" "}
          <button
            onClick={() => {
              setParams((pre) => ({ ...pre, platform: "tradelocker" }));
            }}
            className={params?.platform === "tradelocker" ? "activeBtn" : ""}
            style={{ cursor: "pointer" }}
          >
            Tradelocker
          </button>
        </div>
      </div>
      <div className="table_wrapper">
        {allTraders.length > 0 ? (
          <Table
            params={params}
            setParams={setParams}
            admin={admin}
            tableData={allTraders}
            totalTraders={totalTraders}
            fetchData={fetchTraders}
          />
        ) : (
          <p>No traders found.</p>
        )}
      </div>
    </div>
  );
}

// Table Component
const Table = ({ params, setParams, tableData, totalTraders, fetchData }) => {
  const handleCopyToClipboard = (text) => {
    toast("Copied email", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };
  const dispatch = useDispatch();
  const handleActiveAccount = (row) => {
    row.original?.login_id
      ? dispatch(setActiveAccount(row.original.login_id))
      : dispatch(setActiveAccount(null));
    dispatch(setActiveUser({ ...row.original }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <>
            <div className="column_header">Email</div>
          </>
        ),
        accessor: "Name",
        Cell: ({ row }) => {
          const navigate = useNavigate();

          // Generate a random color once
          const randomColor = React.useMemo(() => {
            return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
          }, []);

          const handleNavigation = () => {
            handleActiveAccount(row);
            navigate("/traders-list-2");
          };

          return (
            <div onClick={handleNavigation}>
              <div className="column_one_wrapper">
                <div
                  className="sno_wrapper"
                  style={{ backgroundColor: randomColor }}
                >
                  {row.original?.email?.charAt(0)}
                </div>
                <div>{row.original?.email}</div>
                <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent navigation on icon click
                    e.preventDefault();
                  }}
                >
                  <CopyToClipboard
                    onCopy={() => {
                      handleCopyToClipboard();
                    }}
                    text={row.original?.email}
                  >
                    <p
                      className="table_copy_button"
                      style={{
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <CopyIcon />
                    </p>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          );
        },
      },

      {
        Header: "Login ID",
        accessor: "login_id",
        Cell: ({ row }) => (
          <Link to="/traders-list-2" onClick={() => handleActiveAccount(row)}>
            {row.original.login_id}
          </Link>
        ),
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({ row }) => moment(row.original.start_date).format("DD-MM-YYYY"),
      },
      {
        Header: "Platform",
        accessor: "trading_platform",
        Cell: ({ row }) => (
          <span style={{ textTransform: "capitalize" }}>
            {row.original.trading_platform}
          </span>
        ),
      },
      {
        Header: "Challenge",
        accessor: "challenge",
        Cell: ({ row }) => {
          return (
            <Link onClick={() => handleActiveAccount(row)} to="/traders-list-2">
              {" "}
              {row.original?.challenge}
            </Link>
          );
        },
      },
      {
        Header: "Challenge Type",
        accessor: "challenge_type",
        Cell: ({ row }) => {
          return (
            <Link onClick={() => handleActiveAccount(row)} to="/traders-list-2">
              {" "}
              {formatText(row.original?.challenge_type)}
            </Link>
          );
        },
      },
      {
        Header: "Phase",
        accessor: "phase",
        Cell: ({ row }) => {
          return (
            <Link onClick={() => handleActiveAccount(row)} to="/traders-list-2">
              {" "}
              {formatText(row.original?.phase)}
            </Link>
          );
        },
      },
      {
        Header: () => (
          <>
            <div className="column_header">Status</div>
          </>
        ),
        accessor: "is_active",
        Cell: ({ row, value }) => {
          return (
            <Link onClick={() => handleActiveAccount(row)} to="/traders-list-2">
              <div
                className={`status_wrapper ${
                  value ? statusClassMap.succeeded : "Inactive_tag"
                }`}
              >
                {row.original.is_active ? "Active" : "Disabled"}
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = React.useState(null);
          const [confirmationModal, setConfirmationModal] =
            React.useState(false);
          const [confirmationModal1, setConfirmationModal1] =
            React.useState(false);
          const open = Boolean(anchorEl);
          const dispatch = useDispatch();
          const idToken = useSelector((state) => state.auth.idToken);
          const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };
          const [data, setData] = useState();
          const [email, setEmail] = useState();

          // Handle status change (enable/disable)
          const handleStatus = async (e) => {
            setConfirmationModal(true);
            setData({ status: e.active ? "disable" : "enable" });
            setEmail(e.email);
            handleClose();
          };

          // Send credentials to the user
          const handleSendCredential = async (row) => {
            const login_id = { login_id: row.original.login_id };
            const response = await sendCredential(idToken, login_id);
            if (response?.status < 399) {
              dispatch(
                returnMessages(response?.data?.detail, response?.status)
              );
            } else {
              const msg =
                response?.response?.data?.detail || "Something went wrong";
              dispatch(returnErrors(msg, 400));
            }
          };

          // Confirm the action for enabling/disabling an account
          const handleUpdateConfirm = async (row, action) => {
            console.log(action, "Action Type");
            try {
              const response = await updateUserAccountReq(idToken, {
                login_id: row.original.login_id,
                type: action,
              });
              if (response?.status < 399) {
                dispatch(
                  returnMessages(
                    `Account ${action}d successfully`,
                    response?.status
                  )
                );
                fetchData(); // Refresh data after action
              } else {
                const msg =
                  response?.response?.data?.detail ||
                  "Failed to update account";
                dispatch(returnErrors(msg, 400));
              }
            } catch (error) {
              console.error("Error updating account:", error);
              dispatch(returnErrors("Error updating account", 500));
            } finally {
              setConfirmationModal(false); // Close modal after action
            }
          };

          return (
            <div>
              {confirmationModal && (
                <ConfirmationModal
                  id={row.original.login_id} // Pass the necessary ID
                  setConfirmationModal={setConfirmationModal} // Pass the state setter
                  onConfirm={() => {
                    const actionType = row.original.is_active
                      ? "disable"
                      : "reinstantiate"; // Determine action type
                    handleUpdateConfirm(row, actionType); // Call the confirm handler
                  }}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: "150px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={() => handleSendCredential(row)}>
                  Send Credential
                </MenuItem>
                {true && (
                  <MenuItem onClick={() => handleStatus(row.original)}>
                    {row.original.is_active ? "Disable" : "Activate"}
                  </MenuItem>
                )}
              </Menu>
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        count={Math.ceil(totalTraders / 20)}
        page={params.page}
        onChange={(e, page) => setParams((prev) => ({ ...prev, page: page }))}
      />
    </>
  );
};

export default AllTable;
