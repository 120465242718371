import React from "react";
import ReactApexChart from "react-apexcharts";
import "./Index.scss";

const ApexChart = ({
  series,
  labels,
  width = 300,
  height = 300, // Default height
  chartType = "pie",
  chartHeading,
  colors,
}) => {
  const options = {
    chart: {
      width: width,
      type: chartType,
      height: height, // Initial height
    },
    labels: labels,
    colors: colors, // Add dynamic colors here

    responsive: [
      {
        breakpoint: 1024, // For tablets
        options: {
          chart: {
            width: 300,
            height: 300, // Adjust height for tablets
          },
          legend: {
            position: "bottom",
            fontSize: '14px',
          },
        },
      },
      {
        breakpoint: 768, // For mobile in landscape mode
        options: {
          chart: {
            width: 250,
            height: 250, // Adjust height for mobile landscape
          },
          legend: {
            position: "bottom",
            fontSize: '12px',
          },
        },
      },
      {
        breakpoint: 480, // For mobile in portrait mode
        options: {
          chart: {
            width: 200,
            height: 200, // Adjust height for mobile portrait
          },
          legend: {
            position: "bottom",
            fontSize: '10px',
          },
        },
      },
    ],
  };

  return (
    <div className="chart-container">
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type={chartType}
          height={options.chart.height} // Dynamically set height
        />
      </div>
      <div id="html-dist" className="chart_heading" style={{ textAlign: "center" }}>
        {chartHeading}
      </div>
    </div>
  );
};

export default ApexChart;
