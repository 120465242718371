import React, { useState } from "react";
import "./style.scss";
import CopyIcon from "../../../assets/icons/copy-blue.svg";
import ArrowLeft from "../../../assets/icons/dropdown-arrow-gray.svg";
import plus from "../../../assets/icons/AddIcon.svg";
import minus from "../../../assets/icons/minus.svg";
import copy from "../../../assets/icons/copy-white.svg";
import IncrementDecrementInput from "./IncrementDecrementInput";
import { UserSearchReq, baseUrl } from "../../../utils/api/apis";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createAffiliateData } from "../../../store/reducers/affiliateSlice";
import axios from "axios";
import Select from "react-select";
// import { returnErrors } from "../../../store/reducers/error";
// import { returnMessages } from "../../../store/reducers/message";
import { ToastContainer, toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { returnErrors } from "../../../store/reducers/error";
import { returnMessages } from "../../../store/reducers/message";

const CreateCodes = () => {
  const [emailOpts, setEmailOpts] = useState([{ label: "", value: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [couponData, setCouponData] = useState({
    email: "",
    code: "",
    percentage: 0,
    percent_repeat: 0,
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
      color: "#000",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "#000",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
      width: "0px",
    }),
    DropdownIndicator: (state) => ({
      display: "none",
      width: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };
  const fetch = async (value) => {
    setIsLoading(true);
    const response = await UserSearchReq(idToken, value);
    setIsLoading(false);
    if (response?.status < 399) {
      let userArray = [];
      response.data.map((item) =>
        userArray.push({
          label: item?.email,
          value: item?.email,
        })
      );

      setEmailOpts(userArray);
    } else {
      if (response.response.data.message) {
        return;
      }
      let msg = response?.response?.data?.detail || "Something went wrong";
      dispatch(returnErrors(msg, 400));
    }
  };

  const handleIncrease = () => {
    if (couponData.percentage < 10) {
      setCouponData({ ...couponData, percentage: couponData.percentage + 1 });
    }
  };
  const handleDecrease = () => {
    if (couponData.percentage > 0) {
      setCouponData({ ...couponData, percentage: couponData.percentage - 1 });
    }
  };
  const handleIncreaseRepeat = () => {
    if (couponData.percent_repeat < 10) {
      setCouponData({
        ...couponData,
        percent_repeat: couponData.percent_repeat + 1,
      });
    }
  };
  const handleDecreaseRepeat = () => {
    if (couponData.percent_repeat > 0) {
      setCouponData({
        ...couponData,
        percent_repeat: couponData.percent_repeat - 1,
      });
    }
  };
  const idToken = useSelector((state) => state.auth.idToken);
  const [codeLink, setCodeLink] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const { code, email } = couponData;
    if (!code || !email) {
      dispatch(returnErrors("Please enter a Coupon Code and Email.", 400));
      return;
    }

    try {
      let config = {
        headers: {
          Authorization: "Bearer " + idToken,
        },
      };
      const res = await axios.post(
        `${baseUrl}affiliates/admin/create-link/`,
        couponData,
        config
      );

      if (res.data.code !== couponData.code) {
        //   dispatch(returnErrors("Please enter a unique value.", 400));
        dispatch(returnErrors("Please enter a unique value", 400));
      } else {
        setCodeLink(res.data.url);
        dispatch(returnMessages("Successfully created coupon Code", 200));
      }
    } catch (error) {
      if (error) {
        dispatch(returnErrors("Failed to create coupon", 400));
        // alert("This code is already taken", 400);
      }
    }
  };
  const handleCopyToClipboard = (text) => {
    if (text !== "") {
      toast(text ? "Text copied to clipboard !" : "NA", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "success",
        theme: "dark",
        className: "custom-toast-container",
      });
    }
  };

  return (
    <div className="affiliate_codes">
      <div className="affiliate_table2">
        <div className="get_a_link_header">
          <div>
            <h4>
              Create Your Affiliate Code -- {codeLink ? couponData.code : ""}
            </h4>
          </div>
          <div>
            {/* <p>
                Lorem ipsum dolor sit amet consectetur. Purus risus lacus vulputate at. Fringilla arcu quisque pharetra risus vitae eu egestas dolor. Id viverra duis ipsum porttitor quis ut. Nunc proin
                ornare fringilla diam.
              </p> */}
          </div>
        </div>
        {/* 2nd div */}
        <div className="get_a_link_conatiner2">
          <div className="coupon_code">
            <div className="common_container">
              <div>
                <h4>Your Unique Coupon Code</h4>
              </div>
              <div className="input_div">
                <input
                  type="text"
                  placeholder="Coupon Code"
                  className="input_container"
                  style={{ textTransform: "uppercase" }}
                  value={couponData.code}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    setCouponData({ ...couponData, code: value });
                  }}
                />
                <ToastContainer />
                <CopyToClipboard
                  onCopy={handleCopyToClipboard}
                  text={couponData.code}
                >
                  <img src={copy} alt="" />
                </CopyToClipboard>
              </div>
            </div>
            <div className="common_container">
              <div>
                <h4>Affiliate Email</h4>
              </div>
              <div className="input_div">
                {/* <input
                  type="email"
                  placeholder="email"
                  className="input_container"
                  style={{}}
                  value={couponData.email}
                  onChange={(e) => {
                    setCouponData({ ...couponData, email: e.target.value });
                  }}
                /> */}
                <Select
                  isLoading={isLoading}
                  placeholder={"Search for a user"}
                  styles={customStyles}
                  options={emailOpts}
                  onInputChange={(value) => {
                    const valid = typeof value;
                    valid === "string" && fetch(value);
                  }}
                  onChange={(selectedOption) => {
                    setCouponData((prev) => ({
                      ...prev,
                      email: selectedOption.value,
                    }));
                  }}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
                <ToastContainer />
                <CopyToClipboard
                  onCopy={handleCopyToClipboard}
                  text={couponData.email}
                >
                  <img src={copy} alt="" />
                </CopyToClipboard>
              </div>
            </div>
            <div className="common_container">
              <div>
                <h4>Your Affiliate link </h4>
              </div>
              <div className="input_div">
                <input
                  type="text"
                  className="copy_text"
                  value={codeLink}
                  //   ref={inputRef}
                  readOnly
                />
                <CopyToClipboard onCopy={handleCopyToClipboard} text={codeLink}>
                  <img src={copy} alt="copylink" className="copylink" />
                </CopyToClipboard>
              </div>
            </div>
            <div className="common_container">
              <div>
                {/* <p>
                    Lorem ipsum dolor sit amet consectetur. Purus risus lacus vulputate at. Fringilla arcu quisque pharetra risus vitae eu egestas dolor. Id viverra duis ipsum porttitor quis ut. Nunc
                    proin ornare fringilla diam.
                  </p> */}
              </div>
              <div>
                <h4>NOTE: Fixed Commission Income rates for Company is 15%</h4>
              </div>
            </div>
          </div>
          <div className="comission_rate">
            <div>
              <h4>Your Commision rate</h4>
            </div>
            <h5>Percentage</h5>
            <div className="rate">
              <div onClick={handleDecrease}>
                <img src={minus} alt="" />
              </div>
              <p>{couponData.percentage}</p>
              <div onClick={handleIncrease}>
                <img src={plus} alt="" />
              </div>
            </div>
            <h5>Percentage Repeat</h5>
            <div className="rate">
              <div onClick={handleDecreaseRepeat}>
                <img src={minus} alt="" />
              </div>
              <p>{couponData.percent_repeat}</p>
              <div onClick={handleIncreaseRepeat}>
                <img src={plus} alt="" />
              </div>
            </div>
            <div>
              <button className="genrate_button" onClick={handleSubmit}>
                Generate
              </button>
            </div>
            <div>
              <p>NOTE: Fixed Commission Income rates for company is 15%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateCodes;
