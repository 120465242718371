import supportsvg from "../../assets/icons/support.svg";
import { useSelector } from "react-redux";

export const useSearchKeywordsData = () => {
  const { custom_permissions } = useSelector((state) => state.auth);

  const sideBarList = [
    { adminAccess: custom_permissions?.includes("risk_management"), path: "/risk-management", content: "Risk Management" },
    { adminAccess: custom_permissions?.includes("trader_list"), path: "/super-trader-list/", content: "Traders List Super Admin" },
    { adminAccess: custom_permissions?.includes("stage_manager"), path: "/stage-management", content: "Stage Management" },
    { adminAccess: custom_permissions?.includes("user_management"), path: "/user-list", content: "User List" },
    { adminAccess: custom_permissions?.includes("account_creation"), path: "/funding-evaluation", content: "Funding Evaluation" },
    { adminAccess: custom_permissions?.includes("certificates"), path: "/CertificateManagement", content: "Certificate Management" },
    { adminAccess: custom_permissions?.includes("payments"), path: "/paymentlist", content: "Payments" },
    { adminAccess: custom_permissions?.includes("payout"), path: "/payout_list", content: "Payout List" },
    { adminAccess: custom_permissions?.includes("coupons"), path: "/coupons", content: "Coupons" },
    { adminAccess: custom_permissions?.includes("affiliate_market"), path: "/affiliate-marketing", content: "Affiliate Marketing" },
    { adminAccess: custom_permissions?.includes("admin_support"), path: "/superadmin", content: "Super Admin Support" },
    { adminAccess: custom_permissions?.includes("ip_log"), path: "/ip-log", content: "IP Log" },
  ];

  // Filter and map sideBarList to create searchKeywordsData
  const searchKeywordsData = sideBarList
    .filter((item) => item.adminAccess) // Include only items with `adminAccess: true`
    .map((item) => ({
      content: item.content,
      path: item.path,
      icon: item.icon || null, // Add icon only if it exists
    }));

  return searchKeywordsData;
};
