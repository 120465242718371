import React, { useEffect, useState } from "react";
import "./style.scss";
import Tabination from "../../reusableComponents/Tabination";
import AccountOverview from "../../layouts/TradeList/AccountOverview";
import TransactionHistory from "../../layouts/TradeList/TransactionHistory";
import ProfileDetails from "../../layouts/TradeList/ProfileDetails";
import { Navigate, Route, Routes } from "react-router-dom";
import TradeHistory from "../../layouts/TradeList/TradeHistory";
import AffiliateTable from "../../layouts/TradeList/AffiliateList";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountListSuccess,
  getAccountMetrics,
  getAccountMetricsFailure,
  getAccountMetricsSuccess,
  getTraderJournal,
  setActiveAccount,
} from "../../store/reducers/accountSlice";
import axios from "axios";
import {
  baseUrl,
  getAccountMetricsRequest,
  getBalanceChartRequest,
} from "../../utils/api/apis";
import { setIsLoading } from "../../store/reducers/authSlice";
function TradersList2() {
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);
  const items = [
    {
      name: "Account Overview",
      path: "account-overview",
    },
    {
      name: "Trade History",
      path: "trade-history",
    },
    // {
    //   name: "Transaction History",
    //   path: "transaction-history",
    // },
    {
      name: "Affiliate List",
      path: "affiliate-list",
    },
    {
      name: "Account Details",
      path: "account-details",
    },
  ];

  const activeUser = useSelector(
    (state) => state.account.accountList.activeUser
  );
  // Hitting search user api and getting user Id and then passing the user ID to fetch user accounts
  useEffect(() => {
    console.log(activeUser);
  }, [activeUser]);
  // handle account metrics
  const [accountsData, setAccountsData] = useState({});
  const accountsDataRedux = useSelector(
    (state) => state.account.accountMetrics.data
  );
  useEffect(() => {
    setAccountsData(accountsDataRedux);
  }, [accountsDataRedux]);
  // handle active account id
  const activeAccountRedux = useSelector(
    (state) => state.account.accountList.activeAccount.id
  );
  const [activeAccountUser, setActiveAccountUser] = useState(null);
  console.log(activeUser, "activeUseractiveUser");
  const FetchUserAccounts = async () => {
    let config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    // const userId = await axios
    //   .get(
    //     `${baseUrl}auth/admin/search-user/?search=${activeUser.email}`,
    //     config
    //   )
    //   .then((res) => Array.isArray(res.data) && res.data[0].id);
    const userId =
      activeUser?.user_id || activeUser?.login_id || activeUser?.id;
    // take a look at this
    const userAccountsList = await axios
      .get(`${baseUrl}account/admin/account-filter/?user_id=${userId}`, config)
      .then((res) => {
        dispatch(getAccountListSuccess(res.data));
        const findSelectedIdFromTable = res?.data?.find(
          (obj) => obj.login_id === activeUser.login_id
        );
        dispatch(
          setActiveAccount(
            findSelectedIdFromTable?.login_id ||
              (res?.data[0] && res?.data[0]?.login_id)
          )
        );
      });
  };

  useEffect(() => {
    FetchUserAccounts();
  }, [activeUser]);

  useEffect(() => {
    activeAccountUser !== null &&
      activeAccountRedux &&
      dispatch(
        getAccountMetrics({ idToken: idToken, loginId: activeAccountRedux })
      );
  }, [activeAccountUser]);

  useEffect(() => {
    setActiveAccountUser(activeAccountRedux);
  }, [activeAccountRedux]);

  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
      <div className="traderslist-2">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="account-overview" replace={true} />}
          />
          <Route
            path="account-overview"
            element={<AccountOverview accountsData={accountsData} />}
          />
          <Route
            path="trade-history"
            element={
              <TradeHistory tableData={accountsData?.account_metrics?.deals} isTradelocker={accountsData.trading_platform}/>
            }
          />
          <Route path="transaction-history" element={<TransactionHistory />} />
          <Route
            path="affiliate-list"
            element={<AffiliateTable activeUser={activeUser} />}
          />
          <Route
            path="account-details"
            element={<ProfileDetails activeUser={activeUser} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default TradersList2;
