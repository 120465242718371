const customStyles = {
  control: (provided) => ({
    ...provided,
    background: "none",
    width: "100%",
    marginTop: "0px",
    height: 60,
    borderRadius: 8,
    border: "1px solid #eaf0f8;",
    boxShadow: "none",
    fontFamily: "DM Sans, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    minHeight: "48px",
    height: "48px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "grey",
    fontSize: "14px",
    fontWeight: 500,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "48px",
    padding: "0 6px",
    fontWeight: 500,
    marginLeft: "10px",
    color: "#000",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    color: "#000",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "48px",
  }),
};
export default customStyles;
